import React, {useContext, useEffect, useState} from 'react';
import Screen from "../../Components/Screen";
import {Button, Card, Modal, Table} from "react-bootstrap";
import firebase from "firebase";
import logo from "../../Images/logo-dark.png"
import {useParams} from 'react-router-dom';
import {PurchaseOrder} from "./PurchaseOrdersCreate";
import moment from "moment";
import {globalUser, Notification, User} from "../../App";
import {toast} from "react-hot-toast";

const PurchaseOrdersView = () => {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()
    const [approver, setApprover] = useState<User>()
    const [raisedBy, setRaisedBy] = useState<User>()
    const [printing, setPrinting] = useState(false);

    const currentUser = useContext(globalUser)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePrint = () => {
        setPrinting(true)
    }

    // @ts-ignore
    let {id} = useParams();

    const getTotalPrice = () => {
        let priceArray: number[] = [];
        // @ts-ignore
        purchaseOrder?.parts.map((partItem) => {
            if (partItem) {
                priceArray.push(partItem.quantity * partItem.price)
            }
        })
        return priceArray.reduce((a, b) => a + b, 0)
    }

    const getPurchaseOrder = async () => {
        const query = firebase.firestore().collection('purchaseOrders').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setPurchaseOrder(doc.data())
        })
    }

    const getApprover = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setApprover({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const getRaisedBy = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setRaisedBy({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const approvePo = async () => {
        if (purchaseOrder) {
            let newPurchaseOrder: PurchaseOrder = {
                ...purchaseOrder,
                approved: true
            }
            const db = firebase.firestore().collection("purchaseOrders");
            await db.doc(purchaseOrder.id.toString()).set(newPurchaseOrder).then(() => {
                getPurchaseOrder()
                handleClose()
                toast.success(`Purchase order approved!`)
            }).then(async () => {
                const newNotification: Notification = {
                    type: "PO Approval",
                    text: "Your purchase order has been approved",
                    link: `/purchase-orders/view/${purchaseOrder.id}`,
                    dateTime: new Date().toDateString(),
                    viewed: false,
                }
                const notifyQuery = firebase.firestore().collection("notifications").doc(purchaseOrder.raisedById)
                await notifyQuery.get().then((doc) => {
                    if (doc.exists) {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(purchaseOrder.raisedById).set({notifications: [newNotification, ...doc.data().notifications]})
                    } else {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(purchaseOrder.raisedById).set({notifications: [newNotification]})
                    }
                })
            })
        }
    }


    useEffect(() => {
        getPurchaseOrder()
    }, [])

    useEffect(() => {
        if (purchaseOrder) {
            getApprover(purchaseOrder.approverId)
            getRaisedBy(purchaseOrder.raisedById)
        }
    }, [purchaseOrder])


    if ((printing && purchaseOrder && approver && raisedBy)) {
        return (
        <div className="purchaseOrdersView">
            <div className="purchaseOrdersCreate-template">
                <img className="mb-5" src={logo}/>
                <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`PURCHASE ORDER NO. ${id}`}</h2>
                <h4 className="purchaseOrdersCreate-template__green-text">SUPPLIER</h4>
                <div className="d-flex flex-column">
                    <p>{purchaseOrder.supplier?.name}</p>
                    <p>{purchaseOrder.supplier?.addressOne}</p>
                    <p>{purchaseOrder.supplier?.townCity}</p>
                    <p>{purchaseOrder.supplier?.postCode}</p>
                </div>
                <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                    <div className="d-flex align-items-center flex-row">
                        <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">FAO:</h5>
                        <p>{`${purchaseOrder.supplierContact?.name}`}</p>
                    </div>
                    <div className="d-flex align-items-center flex-row">
                        <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">REF:</h5>
                        <p>{purchaseOrder.ref}</p>
                    </div>
                    <div className="d-flex align-items-center flex-row">
                        <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DATE:</h5>
                        <p>{moment(purchaseOrder.dateRaised,"DD/MM/YYYY").format("DD/MM/YYYY")}</p>
                    </div>
                </div>
                <Card className="mb-4">
                    <Card.Header>
                        <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – QUANTITY,
                            DESCRIPTION & PRICE</h4>
                    </Card.Header>
                    <Card.Body>
                        <Table  bordered>
                            <thead>
                            <tr>
                                    <th className="text-right tbl-qty-w1">#</th>
                                    <th className='tbl-qty-w4'>Part ID</th>
                                    <th className="">Description, dimension, material & finish</th>
                                    <th className="text-right tbl-qty-w1">QTY</th>
                                    <th className="text-right">Due date</th>
                                    <th className="text-right">Price each</th>
                                    <th className="text-right">Price total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {purchaseOrder.parts.map((partItem, index) => {
                                    console.log(partItem)
                                    return (
                                        <tr>
                                            <td className="text-right">{index + 1}</td>
                                            <td>{partItem.part.id}</td>
                                            <td>{partItem.part.description}, {partItem.part.dimensions}, {partItem.part.material}, {partItem.part.finish}</td>
                                            <td className="text-right">{partItem.quantity}</td>
                                            <td className="text-right">
                                                {moment(new Date(partItem.dueDate)).format("DD/MM/YYYY")}
                                            </td>
                                            <td className="text-right">£{partItem.price.toFixed(2)}</td>
                                            <td className="text-right">£{(partItem.price * partItem.quantity).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                            <tr>
                                <td className="font-weight-bold text-right" colSpan={6}>Total GBP (Excluding VAT)
                                </td>
                                <td className="text-right">£{getTotalPrice().toFixed(2)}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="mb-4">
                    <Card.Header>
                        <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 2.0 – SCOPE OF
                            SUPPLY</h4>
                    </Card.Header>
                    <Card.Body>
                        {purchaseOrder.scope.map((value, index) => {
                            return (
                                <p className="mb-1"><span
                                    className="font-weight-bold purchaseOrdersCreate-template__green-text">{`2.${index + 1} `}</span>{value}
                                </p>
                            )
                        })}
                    </Card.Body>
                </Card>
                <Card className="mb-5">
                    <Card.Header>
                        <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 3.0 – TERMS,
                            CONDITIONS & PAYMENT</h4>
                    </Card.Header>
                    <Card.Body>
                        <p>{purchaseOrder.terms}</p>
                    </Card.Body>
                </Card>
                <div className="purchaseOrdersCreate-template-signatures">
                    <div className="d-flex flex-column">
                        <p>PO raised by</p>
                        <h4 className="mb-0">{raisedBy.name}</h4>
                        <p className="mb-2">{raisedBy.role}</p>
                        <p>For & on behalf of Therco - Serck Ltd</p>
                    </div>
                    <div className="d-flex flex-column text-right">
                        {purchaseOrder.approved ?
                            <>
                                <p>PO authorised by</p>
                                <h4 className="mb-0">{approver.name}</h4>
                                <p className="mb-2">{approver.role}</p>
                                <p>For & on behalf of Therco - Serck Ltd</p>
                            </>
                            :
                            <>
                                <p>PO to be authorised by</p>
                                <h4 className="mb-0">{approver.name}</h4>
                                <p className="mb-2">This PO is not authorised</p>
                                <div className="d-flex flex-row align-items-center mt-5">
                                    <Button
                                        onClick={() => {
                                        }}
                                        className="button-green ml-3">
                                        Edit purchase order
                                    </Button>
                                    <Button
                                        onClick={() => {
                                        }}
                                        className="button-green ml-3">
                                        Delete purchase order
                                    </Button>
                                    {currentUser.currentUser.uid === approver.uid &&
                                    <Button
                                        onClick={() => handleShow()}
                                        className="button-green ml-3">
                                        Approve this PO
                                    </Button>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="mt-4">
                    <p><span className="font-weight-bold">Therco-Serck Ltd</span> Rother Valley Way, Holbrook, Sheffield, S20 3RW, United Kingdom</p>
                    <div className="d-flex flex-row">
                        <p className="mr-2"><span className="font-weight-bold">T:</span> 0114 251 0339</p>
                        <p className="mr-2"><span className="font-weight-bold">F:</span> 0114 251 0564</p>
                        <p className="mr-2"><span className="font-weight-bold">E:</span> info@ThercoSerckHTX.com</p>
                        <p><span className="font-weight-bold">W:</span> ThercoSerckHTX.com</p>
                    </div>
                </div>
                {purchaseOrder.approved &&
                        <div className="d-flex flex-row justify-content-end w-100 mt-5 PO-printing">
                            <div className="d-flex flex-row align-items-center">
                                <Button
                                    onClick={() => setPrinting(false)}
                                    className="button-green ml-3">
                                    Cancel preview
                                </Button>
                                <Button
                                    onClick={() => window.print()}
                                    className="button-green ml-3">
                                    Download PDF
                                </Button>
                            </div>
                        </div>
                }
            </div>
        </div>)
    }

    return (
        <Screen active="Purchase orders view">
            {(purchaseOrder && approver && raisedBy) &&
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo}/>
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`PURCHASE ORDER NO. ${id}`}</h2>
                    <h4 className="purchaseOrdersCreate-template__green-text">SUPPLIER</h4>
                    <div className="d-flex flex-column">
                        <p>{purchaseOrder.supplier?.name}</p>
                        <p>{purchaseOrder.supplier?.addressOne}</p>
                        <p>{purchaseOrder.supplier?.townCity}</p>
                        <p>{purchaseOrder.supplier?.postCode}</p>
                    </div>
                    <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">FAO:</h5>
                            <p>{`${purchaseOrder.supplierContact?.name}`}</p>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">REF:</h5>
                            <p>{purchaseOrder.ref}</p>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DATE:</h5>
                            <p>{moment(purchaseOrder.dateRaised,"DD/MM/YYYY").format("DD/MM/YYYY")}</p>
                        </div>
                    </div>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – QUANTITY,
                                DESCRIPTION & PRICE</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table  bordered>
                                <thead>
                                <tr>
                                    <th className="text-right tbl-qty-w1">#</th>
                                    <th className='tbl-qty-w4'>Part ID</th>
                                    <th className="">Description, dimension, material & finish</th>
                                    <th className="text-right tbl-qty-w1">QTY</th>
                                    <th className="text-right">Due date</th>
                                    <th className="text-right">Price each</th>
                                    <th className="text-right">Price total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {purchaseOrder.parts.map((partItem, index) => {
                                    console.log(partItem)
                                    return (
                                        <tr>
                                            <td className="text-right">{index + 1}</td>
                                            <td>{partItem.part.id}</td>
                                            <td>{partItem.part.description}, {partItem.part.dimensions}, {partItem.part.material}, {partItem.part.finish}</td>
                                            <td className="text-right">{partItem.quantity}</td>
                                            <td className="text-right">
                                                {moment(new Date(partItem.dueDate)).format("DD/MM/YYYY")}
                                            </td>
                                            <td className="text-right">£{partItem.price.toFixed(2)}</td>
                                            <td className="text-right">£{(partItem.price * partItem.quantity).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className="font-weight-bold text-right" colSpan={6}>Total GBP (EXCLUDING VAT)
                                    </td>
                                    <td className="text-right">£{getTotalPrice().toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold text-right" colSpan={6}>VAT
                                    </td>
                                    <td className="text-right">£{((getTotalPrice() / 100) * 20).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold text-right" colSpan={6}>Total GBP
                                    </td>
                                    <td className="text-right">£{(getTotalPrice() + ((getTotalPrice() / 100) * 20)).toFixed(2)}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 2.0 – SCOPE OF
                                SUPPLY</h4>
                        </Card.Header>
                        <Card.Body>
                            {purchaseOrder.scope.map((value, index) => {
                                return (
                                    <p className="mb-1"><span
                                        className="font-weight-bold purchaseOrdersCreate-template__green-text">{`2.${index + 1} `}</span>{value}
                                    </p>
                                )
                            })}
                        </Card.Body>
                    </Card>
                    <Card className="mb-5">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 3.0 – TERMS,
                                CONDITIONS & PAYMENT</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>{purchaseOrder.terms}</p>
                        </Card.Body>
                    </Card>
                    <div className="purchaseOrdersCreate-template-signatures">
                        <div className="d-flex flex-column">
                            <p>PO raised by</p>
                            <h4 className="mb-0">{raisedBy.name}</h4>
                            <p className="mb-2">{raisedBy.role}</p>
                            <p>For & on behalf of Therco - Serck Ltd</p>
                        </div>
                        <div className="d-flex flex-column text-right">
                            {purchaseOrder.approved ?
                                <>
                                    <p>PO authorised by</p>
                                    <h4 className="mb-0">{approver.name}</h4>
                                    <p className="mb-2">{approver.role}</p>
                                    <p>For & on behalf of Therco - Serck Ltd</p>
                                </>
                                :
                                <>
                                    <p>PO to be authorised by</p>
                                    <h4 className="mb-0">{approver.name}</h4>
                                    <p className="mb-2">This PO is not authorised</p>
                                    <div className="d-flex flex-row align-items-center mt-5">
                                        <Button
                                            onClick={() => {
                                            }}
                                            className="button-green ml-3">
                                            Edit purchase order
                                        </Button>
                                        <Button
                                            onClick={() => {
                                            }}
                                            className="button-green ml-3">
                                            Delete purchase order
                                        </Button>
                                        {currentUser.currentUser.uid === approver.uid &&
                                        <Button
                                            onClick={() => handleShow()}
                                            className="button-green ml-3">
                                            Approve this PO
                                        </Button>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {purchaseOrder.approved &&
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            <Button
                                onClick={() => handlePrint()}
                                className="button-green ml-3">
                                View preview & print
                            </Button>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Please confirm approval</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            approvePo()
                        }}
                        className="button-green w-auto">
                        Approve
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default PurchaseOrdersView