import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import { useParams } from 'react-router-dom';
import moment from "moment";
import { toast } from "react-hot-toast";
import { Form, Table, Button } from "react-bootstrap";
import { SalesOrder } from "./OrderProcessing";
import DatePicker from "react-datepicker";

export interface OrderAcknowledgement {
    id: string;
    salesOrder: SalesOrder;
    scope: string;
    warranty: string;
    deliveryDate: string;
    delivery: number;
    totalPrice: number;
    dateRaised: string;
}

const ViewOrderAcknowledgement = () => {
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [preview, setPreview] = useState<boolean>(false)

    // @ts-ignore
    let { id } = useParams();

    const getSalesOrder = async () => {
        const query = firebase.firestore().collection('salesOrders').doc(`SO${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setSalesOrder(doc.data())
        })
    }

    useEffect(() => {
        getSalesOrder()
    }, [])

    let total = 0

    if (salesOrder) {
        // @ts-ignore
        salesOrder.parts.map((value, index) => total = total + (salesOrder.quote.itemPrices[index].price * value.quantity) + salesOrder.OA?.delivery
        );
    }

    if (preview) {
        return (
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <h3 className="green-section-header mt-3">ORDER ACKNOWLEDGEMENT</h3>
                        <p className="green-section-header font-weight-bold mt-1">CLIENT PO: <span
                            className="font-weight-normal black-text">{salesOrder && salesOrder.customerPo}</span></p>
                        <p className="green-section-header font-weight-bold mt-1">OUR REF: <span
                            className="font-weight-normal black-text">TN{salesOrder && salesOrder.quote.enquiry.tnNumber} / SO{salesOrder && salesOrder.quote.enquiry.tnNumber}</span>
                        </p>
                        <p className="green-section-header font-weight-bold mt-1">DATE: <span
                            className="font-weight-normal black-text">{moment().format('Do MMMM YYYY')}</span></p>
                    </div>
                    <div>
                        <p className="font-weight-bold">DELIVERY ADDRESS</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryTownCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryPostCode}</p>
                    </div>
                    <div>
                        <p className="font-weight-bold">INVOICE ADDRESS</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceTownCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoicePostCode}</p>
                    </div>
                </div>
                <h5 className="green-section-header mt-5">SECTION 1.0 - QUANTITY, DESCRIPTION & PRICE</h5>
                <Table bordered hover>
                <thead>
                        <tr>
                            <th className="text-right tbl-qty-w1">QTY</th>
                            <th className='tbl-qty-w4'>Part number</th>
                            <th>Description</th>
                            <th className="text-right tbl-qty-w3">Unit price</th>
                            <th className="text-right">Line price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrder && salesOrder.parts.map((value, index) => {


                            return (
                                <tr>
                                    <td className="text-right">{value.quantity}</td>
                                    <td>{value.partNumber}</td>
                                    <td>{value.description}</td>
                                    <td className="text-right">£{salesOrder.quote.itemPrices[index].price.toFixed(2)}</td>
                                    <td className="text-right">£{(salesOrder.quote.itemPrices[index].price * value.quantity).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Delivery</td>
                            <td className='small-qty text-right'>
                                £{salesOrder?.OA?.delivery.toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Total</td>
                            <td className="text-right">
                                £{total.toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h5 className="green-section-header mt-4">SECTION 2.0 - SCOPE OF SUPPLY</h5>
                <p>{salesOrder?.OA?.scope}</p>
                <h5 className="green-section-header mt-4">SECTION 3.0 - DELIVERY</h5>
                <p>Based on material availability and shop loadings at the time of acknowledging your order, we estimate that the equipment will be delivered on {salesOrder?.OA?.deliveryDate}</p>
                <h5 className="green-section-header mt-4">SECTION 4.0 - WARRANTY</h5>
                <p>{salesOrder?.OA?.warranty}</p>
                <h5 className="green-section-header mt-4">SECTION 5.0 - TERMS, CONDITIONS & PAYMENT</h5>
                <p>THERCO-SERCK Terms and Conditions of Sale (available on request) apply to this order, for payment, please use the following account;
                    Lloyds Bank plc, 14 Church Street, Sheffield, S1 1HP United Kingdom
                </p>
                <p>Account name: Therco – Serck Ltd | Account number 66135968 | Sort Code 30-97-51 | IBAN GB19LOYD30975166135968 | BIC LOYDGB21040</p>
                <div className="d-flex flex-row justify-content-end w-100 PO-printing">
                    <Button
                        onClick={() => setPreview(!preview)}
                        className="button-green mt-5 w-auto">
                        Cancel preview
                    </Button>
                    <Button
                        onClick={() => window.print()}
                        className="button-green mt-5 ml-2 w-auto">
                        Download PDF
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Screen active="Order acknowledgement">
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <h3 className="green-section-header mt-3">ORDER ACKNOWLEDGEMENT</h3>
                        <p className="green-section-header font-weight-bold mt-1">CLIENT PO: <span
                            className="font-weight-normal black-text">{salesOrder && salesOrder.customerPo}</span></p>
                        <p className="green-section-header font-weight-bold mt-1">OUR REF: <span
                            className="font-weight-normal black-text">TN{salesOrder && salesOrder.quote.enquiry.tnNumber} / SO{salesOrder && salesOrder.quote.enquiry.tnNumber}</span>
                        </p>
                        <p className="green-section-header font-weight-bold mt-1">DATE: <span
                            className="font-weight-normal black-text">{moment().format('Do MMMM YYYY')}</span></p>
                    </div>
                    <div>
                        <p className="font-weight-bold">DELIVERY ADDRESS</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryTownCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryPostCode}</p>
                    </div>
                    <div>
                        <p className="font-weight-bold">INVOICE ADDRESS</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceTownCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoicePostCode}</p>
                    </div>
                </div>
                <h5 className="green-section-header mt-5">SECTION 1.0 - QUANTITY, DESCRIPTION & PRICE</h5>
                <Table bordered hover>
                <thead>
                        <tr>
                            <th className="text-right tbl-qty-w1">QTY</th>
                            <th className='tbl-qty-w4'>Part number</th>
                            <th>Description</th>
                            <th className="text-right tbl-qty-w3">Unit price</th>
                            <th className="text-right">Line price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrder && salesOrder.parts.map((value, index) => {


                            return (
                                <tr>
                                    <td className="text-right">{value.quantity}</td>
                                    <td>{value.partNumber}</td>
                                    <td>{value.description}</td>
                                    <td className="text-right">£{salesOrder.quote.itemPrices[index].price.toFixed(2)}</td>
                                    <td className="text-right">£{(salesOrder.quote.itemPrices[index].price * value.quantity).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Delivery</td>
                            <td className='small-qty text-right'>
                                £{salesOrder?.OA?.delivery.toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Total</td>
                            <td className="text-right">
                                £{total.toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h5 className="green-section-header mt-4">SECTION 2.0 - SCOPE OF SUPPLY</h5>
                <p>{salesOrder?.OA?.scope}</p>
                <h5 className="green-section-header mt-4">SECTION 3.0 - DELIVERY</h5>
                <p>Based on material availability and shop loadings at the time of acknowledging your order, we estimate that the equipment will be delivered on {salesOrder?.OA?.deliveryDate}</p>
                <h5 className="green-section-header mt-4">SECTION 4.0 - WARRANTY</h5>
                <p>{salesOrder?.OA?.warranty}</p>
                <h5 className="green-section-header mt-4">SECTION 5.0 - TERMS, CONDITIONS & PAYMENT</h5>
                <p>THERCO-SERCK Terms and Conditions of Sale (available on request) apply to this order, for payment, please use the following account;
                    Lloyds Bank plc, 14 Church Street, Sheffield, S1 1HP United Kingdom
                </p>
                <p>Account name: Therco – Serck Ltd | Account number 66135968 | Sort Code 30-97-51 | IBAN GB19LOYD30975166135968 | BIC LOYDGB21040</p>
                <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => setPreview(!preview)}
                        className="button-green mt-5 w-auto">
                        View preview & print
                    </Button>
                </div>
            </div>
        </Screen>
    )
}

export default ViewOrderAcknowledgement