import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Screen from "../Components/Screen";
import { Button, Card, Col, Form, FormControl, Modal, Nav, Row, Tab } from "react-bootstrap";
import { MdNoteAdd, MdStore } from "react-icons/md";
import searchIcon from "../Images/icons8-search-96 (1).png";
import firebase from "firebase";
import InfiniteScroll from "react-infinite-scroll-component";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import BOM from "../Components/BOM";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { staticParts } from '../Static Data/parts';
import moment from 'moment';

interface dropdown {
    productCategory: string[]
    materialGrade: string[]
    materialGroup: string[]
    finish: string[]
    qaRequirement: string[]
    nominalCodes: string[]
    thermalDesign: string[]
    mechanicalDesign: string[]
    draughting: string[]
}

export interface Part {
    id: string;
    description: string;
    certification?: string;
    dimensions: string;
    material: string;
    finish: string;
    qaRequirement: string[];
    nominalCodePurchasing: string;
    nominalCodeSales: string;
    productType: string;
    materialGroup: string;
    notes: { note: string, date: string }[];
    price: string;
    stock: number;
    stockLocation?: string
    supplierName: string;
}

const columns = [
    { id: 'partNumber', label: 'Part Number', minWidth: 170 },
    { id: 'description', label: 'Description', minWidth: 170 },
    {
        id: 'dimensions',
        label: 'Dimensions',
        minWidth: 100,
    },
    {
        id: 'material',
        label: 'Material Grade',
        minWidth: 170,
    },
    {
        id: 'stock',
        label: 'Stock',
        minWidth: 170,
    },
];

function createData(partNumber: string, part: Part) {
    return { partNumber, ...part };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: `90vh`,
    },
});


const Parts = () => {
    const searchPartRef: MutableRefObject<HTMLTableRowElement | null> = useRef(null);
    const [data, setData] = useState<any>([])
    const [partsList, setPartsList] = useState<Part[]>([])
    const [selectedPart, setSelectedPart] = useState<Part>();
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = useState<string>("");
    const [bomSeq, setBomSeq] = useState<string>("");
    const [bomQuantity, setBomQuantity] = useState("");
    const [adding, setAdding] = useState(false);
    const [restrictNav, setRestrictNav] = useState(false);
    const [partCount, setPartCount] = useState<number>(0);
    const [bom, setBom] = useState<any[]>([])
    const [newBom, setNewBom] = useState<any[]>([])
    const [editBom, setEditBom] = useState<{ partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }>()
    const [editingBom, setEditingBom] = useState<boolean>(false)
    const [sectionTwo, setSectionTwo] = useState<string[]>([])
    const [sectionFive, setSectionFive] = useState<string[]>([])
    const [sectionSix, setSectionSix] = useState<string[]>([])
    const [sectionSeven, setSectionSeven] = useState<string[]>([])
    const [sectionEight, setSectionEight] = useState<string[]>([])
    const [sectionNine, setSectionNine] = useState<string[]>([])
    const [sectionTen, setSectionTen] = useState<string[]>([])
    const [dropdowns, setDropdowns] = useState<dropdown>({
        draughting: [],
        finish: [],
        materialGrade: [],
        materialGroup: [],
        mechanicalDesign: [],
        nominalCodes: [],
        productCategory: [],
        qaRequirement: [],
        thermalDesign: []
    })

    const [note, setNote] = useState<string>("")
    const [stockDate, setStockDate] = useState(new Date().toDateString());

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const classes = useStyles();

    const importParts = async () => {
        staticParts.map(async (part) => {
            let partNumber = part.PARTNUMBER.replaceAll(/\s+/g, '');
            partNumber = partNumber.replaceAll("/", "-")
            let cost = 0
            if (part.COST !== "") {
                cost = parseInt(part.COST.substring(1))
            }
            const temp = {
                description: part.DESCRIPTION,
                dimensions: part.DIMENSION,
                finish: "",
                material: part.MATERIAL,
                materialGroup: "",
                nominalCodePurchasing: "",
                nominalCodeSales: "",
                notes: part.COMMENTS,
                price: cost,
                productType: "",
                qaRequirement: "",
                stock: part.STOCK,
                supplierName: part.SUPPLIER
            }
            if (partNumber !== "") {
                await firebase.firestore().collection("parts").doc(partNumber).set(temp)
                console.log(`Part ${part.PARTNUMBER} imported to database`)
            }
        })

    }

    const getDropdowns = async () => {
        await firebase.firestore().collection("dropdowns").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id)
                if (doc.id === "sections") {
                    Object.keys(doc.data().values).map((value) => {
                        switch (value) {
                            case 'sectionTwo':
                                setSectionTwo(doc.data().values[value])
                                break;
                            case 'sectionFive':
                                setSectionFive(doc.data().values[value])
                                break;
                            case 'sectionSix':
                                setSectionSix(doc.data().values[value])
                                break;
                            case 'sectionSeven':
                                setSectionSeven(doc.data().values[value])
                                break;
                            case 'sectionEight':
                                setSectionEight(doc.data().values[value])
                                break;
                            case 'sectionNine':
                                setSectionNine(doc.data().values[value])
                                break;
                            case 'sectionTen':
                                setSectionTen(doc.data().values[value])
                                break;
                            default:
                                console.log("none")
                                break;
                        }
                    })
                }

                const temp: dropdown = dropdowns
                // @ts-ignore
                temp[doc.id] = doc.data().values
                setDropdowns(temp)
            });
        });
    }

    const searchPart = async () => {
        const collectionRef = firebase.firestore().collection("parts");
        const docRef = collectionRef.doc(searchText);
        let temp: Part[] = [];

        // Step 1: Fetch the specific document
        const doc = await docRef.get();

        if (doc.exists) {
            const orderField = '__name__'; // Using document ID for ordering

            // Step 2: Fetch 15 documents before the current document
            const previousDocsSnapshot = await collectionRef
                .orderBy(orderField, 'asc')
                .endBefore(doc.id)
                .limitToLast(10)
                .get();

            previousDocsSnapshot.forEach((previousDoc) => {
                temp.push(createData(
                    previousDoc.id,
                    {
                        id: previousDoc.id,
                        // @ts-ignore
                        description: previousDoc.data().description,
                        // @ts-ignore
                        dimensions: previousDoc.data().dimensions,
                        // @ts-ignore
                        material: previousDoc.data().material,
                        // @ts-ignore
                        finish: previousDoc.data().finish,
                        // @ts-ignore
                        qaRequirement: previousDoc.data().qaRequirement,
                        // @ts-ignore
                        nominalCodePurchasing: previousDoc.data().nominalCodePurchasing,
                        // @ts-ignore
                        nominalCodeSales: previousDoc.data().nominalCodeSales,
                        // @ts-ignore
                        productType: previousDoc.data().productType,
                        // @ts-ignore
                        materialGroup: previousDoc.data().materialGroup,
                        // @ts-ignore
                        notes: previousDoc.data().notes,
                        // @ts-ignore
                        stockLocation: previousDoc.data().stockLocation,
                        // @ts-ignore
                        price: `£${previousDoc.data().price}`,
                        // @ts-ignore
                        stock: previousDoc.data().stock,
                        // @ts-ignore
                        supplierName: previousDoc.data().supplierName,
                    }
                ));
            });

            // Step 3: Add the current document to the list
            temp.push(createData(
                doc.id,
                {
                    id: doc.id,
                    // @ts-ignore
                    description: doc.data().description,
                    // @ts-ignore
                    dimensions: doc.data().dimensions,
                    // @ts-ignore
                    material: doc.data().material,
                    // @ts-ignore
                    finish: doc.data().finish,
                    // @ts-ignore
                    qaRequirement: doc.data().qaRequirement,
                    // @ts-ignore
                    nominalCodePurchasing: doc.data().nominalCodePurchasing,
                    // @ts-ignore
                    nominalCodeSales: doc.data().nominalCodeSales,
                    // @ts-ignore
                    productType: doc.data().productType,
                    // @ts-ignore
                    materialGroup: doc.data().materialGroup,
                    // @ts-ignore
                    notes: doc.data().notes,
                    // @ts-ignore
                    stockLocation: doc.data().stockLocation,
                    // @ts-ignore
                    price: `£${doc.data().price}`,
                    // @ts-ignore
                    stock: doc.data().stock,
                    // @ts-ignore
                    supplierName: doc.data().supplierName,
                }
            ));

            // Step 4: Fetch 15 documents after the current document
            const nextDocsSnapshot = await collectionRef
                .orderBy(orderField, 'asc')
                .startAfter(doc.id)
                .limit(10)
                .get();

            nextDocsSnapshot.forEach((nextDoc) => {
                temp.push(createData(
                    nextDoc.id,
                    {
                        id: nextDoc.id,
                        // @ts-ignore
                        description: nextDoc.data().description,
                        // @ts-ignore
                        dimensions: nextDoc.data().dimensions,
                        // @ts-ignore
                        material: nextDoc.data().material,
                        // @ts-ignore
                        finish: nextDoc.data().finish,
                        // @ts-ignore
                        qaRequirement: nextDoc.data().qaRequirement,
                        // @ts-ignore
                        nominalCodePurchasing: nextDoc.data().nominalCodePurchasing,
                        // @ts-ignore
                        nominalCodeSales: nextDoc.data().nominalCodeSales,
                        // @ts-ignore
                        productType: nextDoc.data().productType,
                        // @ts-ignore
                        materialGroup: nextDoc.data().materialGroup,
                        // @ts-ignore
                        notes: nextDoc.data().notes,
                        // @ts-ignore
                        stockLocation: nextDoc.data().stockLocation,
                        // @ts-ignore
                        price: `£${nextDoc.data().price}`,
                        // @ts-ignore
                        stock: nextDoc.data().stock,
                        // @ts-ignore
                        supplierName: nextDoc.data().supplierName,
                    }
                ));
            });

            setPartsList(temp);
            if (searchPartRef.current) {
                searchPartRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        } else {
            console.log("No such document!");
        }
    };


    const getInitialData = async () => {
        let temp: Part[] = []
        const query = firebase.firestore().collection('parts')
            .limit(30)
        // Users.
        const parts = await query.get()
        console.log("Request, Parts, Initial data")
        parts.docs.map((doc) => {
            temp.push(createData(
                doc.id,
                {
                    id: doc.id,
                    certification: doc.data().certification || "",
                    description: doc.data().description,
                    dimensions: doc.data().dimensions,
                    material: doc.data().material,
                    finish: doc.data().finish,
                    qaRequirement: doc.data().qaRequirement || "",
                    nominalCodePurchasing: doc.data().nominalCodePurchasing,
                    nominalCodeSales: doc.data().nominalCodeSales,
                    productType: doc.data().productType,
                    materialGroup: doc.data().materialGroup,
                    stockLocation: doc.data().stockLocation,
                    notes: doc.data().notes,
                    price: `£${doc.data().price}`,
                    stock: doc.data().stock,
                    supplierName: doc.data().supplierName,
                }
            ))
        })
        // ..
        setPartsList(temp)
        setData(parts)
    }

    const getPartCount = async () => {
        var docRef = firebase.firestore().collection("partCounter").doc('counter');
        let temp: number = 0
        await docRef.get().then((doc) => {
            if (doc.exists) {
                temp = doc?.data()?.count
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        console.log(temp)
        setPartCount(temp)
    }

    const getData = async () => {
        let temp: Part[] = []
        const lastVisible = data && data.docs[data.docs.length - 1]

        // Query.
        const query = firebase.firestore().collection('parts')
            .startAfter(lastVisible)
            .limit(30)

        // Users.
        const parts = await query.get()
        console.log("Request, Parts, get more data")
        parts.docs.map((doc) => {
            temp.push(createData(
                doc.id,
                {
                    id: doc.id,
                    certification: doc.data().certification || "",
                    description: doc.data().description,
                    dimensions: doc.data().dimensions,
                    material: doc.data().material,
                    finish: doc.data().finish,
                    qaRequirement: doc.data().qaRequirement || "",
                    nominalCodePurchasing: doc.data().nominalCodePurchasing,
                    nominalCodeSales: doc.data().nominalCodeSales,
                    productType: doc.data().productType,
                    materialGroup: doc.data().materialGroup,
                    stockLocation: doc.data().stockLocation,
                    notes: doc.data().notes,
                    price: `£${doc.data().price}`,
                    stock: doc.data().stock,
                    supplierName: doc.data().supplierName,
                }
            ))
        })
        // ..
        setPartsList([...partsList, ...temp])
        setData(parts)
    }

    const editPart = async (values: any) => {
        const editedPart = {
            certification: values.certification,
            description: values.description,
            dimensions: values.dimensions,
            finish: values.finish,
            material: values.material,
            materialGroup: values.materialGroup,
            nominalCodePurchasing: values.nominalCodePurchasing,
            nominalCodeSales: values.nominalCodeSales,
            stockLocation: values.stockLocation,
            price: values.price,
            productType: values.productType,
            stock: values.stock,
            supplierName: values.supplierName,
        }
        await firebase.firestore().collection("parts").doc(selectedPart?.id).set(editedPart).then(() => {
            toast.success(`${selectedPart?.id} updated successfully`)
        })
    }

    const addPart = async (values: any) => {
        const newPart = {
            description: values.description || "",
            dimensions: values.dimensions || "",
            certification: values.certification || "",
            finish: values.finish || "",
            material: values.material || "",
            materialGroup: values.materialGroup || "",
            nominalCodePurchasing: values.nominalCodePurchasing || "",
            nominalCodeSales: values.nominalCodeSales || "",
            stockLocation: values.stockLocation || "",
            price: values.price || 0,
            productType: values.productType || "",
            stock: values.stock || 0,
            supplierName: values.supplierName || "",
        }
        await firebase.firestore().collection("parts").doc(values?.id).get().then(async (doc) => {
            if (!doc.exists) {
                await firebase.firestore().collection("parts").doc(values?.id).set(newPart).then(() => {
                    toast.success(`${values?.id} added successfully`)
                    setRestrictNav(false)
                    setAdding(false)
                })
            } else {
                toast.error(`A part with this number already exists`)
            }
        })
        var docRef = firebase.firestore().collection("partCounter").doc('counter');
        let temp: number = 0
        await docRef.get().then((doc) => {
            if (doc.exists) {
                temp = doc?.data()?.count
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        const newTemp = (temp + 1)
        await firebase.firestore().collection("partCounter").doc("counter").set({ count: newTemp }).then(() => getPartCount())
    }

    const setSoPartNumber = async (values: any) => {
        const query = firebase.firestore().collection("parts").doc(selectedPart?.id)
        await query.get().then(async (doc) => {
            if (doc.exists) {
                const soQuery = firebase.firestore().collection("soPartsLink").doc(values.soNumber)
                await soQuery.get().then((soDoc) => {
                    if (soDoc.exists) {
                        soQuery.update(
                            {
                                parts: firebase.firestore.FieldValue.arrayUnion({
                                    id: doc.id,
                                    part: doc.data(),
                                    quantity: values.soQuantity
                                })
                            }
                        )
                    } else {
                        soQuery.set({ parts: [{ id: doc.id, part: doc.data(), quantity: values.soQuantity }] })
                    }
                })
            }
        }).then(() => toast.success(`${selectedPart?.id}`))
    }

    const setBillOfMaterials = async (values: { partNumber: string, partQuantity: number }) => {
        const partQuery = firebase.firestore().collection("parts").doc(selectedPart?.id)
        await partQuery.get().then(async (partDoc) => {
            if (partDoc.exists) {
                const query = firebase.firestore().collection("billPartsLink").doc(selectedPart?.id)
                await query.get().then((doc) => {
                    if (doc.exists) {
                        query.update({
                            subParts: firebase.firestore.FieldValue.arrayUnion(values)
                        })
                    } else {
                        query.set({ subParts: [values] })
                    }
                }).then(() => {
                    toast.success(`Sub part successfully added`)
                    getBillOfMaterials()
                })
            } else {
                toast.error("This part does not exist. Please add it to the parts list first")
            }
        }).then(() => {
            setBom([])
            getBillOfMaterials().then((subParts) => {
                if (subParts) {
                    subParts.map(async (part: { partNumber: string, partQuantity: number }, index: number) => {
                        const query = firebase.firestore().collection("parts").doc(part.partNumber)
                        await query.get().then((doc) => {
                            // @ts-ignore
                            setBom(bom.concat({
                                ...part,
                                level: 1,
                                sequence: index,
                                subSequence: index,
                                // @ts-ignore
                                description: `${doc.data().description}${doc.data().dimensions !== "" ? `, ${doc.data().dimensions}` : ""}${doc.data().material !== "" ? `, ${doc.data().material}` : ""}`

                            }))
                            checkForSubs(part.partNumber).then((subCheck) => {
                                if (subCheck) {
                                    getBillOfMaterialsSubsById(part.partNumber, 1, index, index)
                                }
                            })
                        })
                    })
                }
            })
        })
    }

    const getBillOfMaterials = async () => {
        const query = firebase.firestore().collection("billPartsLink").doc(selectedPart?.id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, get BOM")
            if (doc.exists) {
                // @ts-ignore
                return (doc.data().subParts)
            }
        })
    }

    const getBillOfMaterialsSubsById = async (id: string, level: number, sequence: number, subSequence: number) => {
        const query = firebase.firestore().collection("billPartsLink").doc(id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, getBOM by ID")
            if (doc.exists) {
                // @ts-ignore
                doc.data().subParts.map(async (subDoc, index) => {
                    const query = firebase.firestore().collection("parts").doc(subDoc.partNumber)
                    await query.get().then((doc) => {
                        // @ts-ignore
                        setBom(bom.concat({
                            ...subDoc,
                            level: (level + 1),
                            sequence: sequence,
                            subSequence: (subSequence + index),
                            // @ts-ignore
                            description: `${doc.data().description}${doc.data().dimensions !== "" ? `, ${doc.data().dimensions}` : ""}${doc.data().material !== "" ? `, ${doc.data().material}` : ""}`
                        }))
                        checkForSubs(subDoc.partNumber).then((subCheck) => {
                            if (subCheck) {
                                getBillOfMaterialsSubsById(subDoc.partNumber, level + 1, sequence, (subSequence + index))
                            }
                        })
                    })
                })
            }
        })
    }

    const deleteBillRow = async (pn: string) => {
        const query = firebase.firestore().collection("billPartsLink").doc(selectedPart?.id)
        return await query.get().then((doc) => {
            if (doc.exists) {
                // @ts-ignore
                const newSubs = doc.data().subParts.filter((item) => item.partNumber !== pn)
                query.set({
                    subParts: newSubs
                }).then(() => {
                    setBom([])
                    getBillOfMaterials().then((subParts) => {
                        if (subParts) {
                            subParts.map(async (part: { partNumber: string, partQuantity: number }, index: number) => {
                                const query = firebase.firestore().collection("parts").doc(part.partNumber)
                                await query.get().then((doc) => {
                                    // @ts-ignore
                                    setBom(bom.concat({
                                        ...part,
                                        level: 1,
                                        sequence: index,
                                        subSequence: index,
                                        // @ts-ignore
                                        description: `${doc.data().description}${doc.data().dimensions !== "" ? `, ${doc.data().dimensions}` : ""}${doc.data().material !== "" ? `, ${doc.data().material}` : ""}`

                                    }))
                                    checkForSubs(part.partNumber).then((subCheck) => {
                                        if (subCheck) {
                                            getBillOfMaterialsSubsById(part.partNumber, 1, index, index)
                                        }
                                    })
                                })
                            })
                        }
                    })
                })
            }
        })
    }

    const checkForSubs = async (id: string) => {
        const query = firebase.firestore().collection("billPartsLink").doc(id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, Initial data")
            if (doc.exists) {
                return true
            } else {
                return false
            }
        })
    }

    const updateData = async (id: string, temp: any) => {
        await firebase.firestore().collection("parts").doc(id).set(temp)
        console.log("Updated " + id)
    }

    const fixAllData = async () => {
        await firebase.firestore().collection("parts").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id)
                const temp = doc.data()
                temp.price = 0;
                const notes: { note: string, username: string, date: string }[] = []
                temp.notes = notes
                updateData(doc.id, temp)
            });
        });
    }

    useEffect(() => {
        getInitialData()
        getPartCount()
        getDropdowns()
    }, [])

    useEffect(() => {
        getBillOfMaterials().then((subParts) => {
            if (subParts) {
                subParts.map(async (part: { partNumber: string, partQuantity: number }, index: number) => {
                    const query = firebase.firestore().collection("parts").doc(part.partNumber)
                    await query.get().then((doc) => {
                        // @ts-ignore
                        setBom(bom.concat({
                            ...part,
                            level: 1,
                            sequence: index,
                            subSequence: index,
                            // @ts-ignore
                            description: `${doc.data().description}${doc.data().dimensions !== "" ? `, ${doc.data().dimensions}` : ""}${doc.data().material !== "" ? `, ${doc.data().material}` : ""}`
                        }))
                        checkForSubs(part.partNumber).then((subCheck) => {
                            if (subCheck) {
                                getBillOfMaterialsSubsById(part.partNumber, 1, index, index)
                            }
                        })
                    })
                })
            }
        })
    }, [selectedPart])


    useEffect(() => {
        if (bom.length === 0) {
            setNewBom(bom)
        } else {
            setNewBom(newBom.concat(...bom))
        }
    }, [bom])

    const mobileQuery = window.matchMedia('(max-width: 850px)')

    const addNote = async () => {
        if (selectedPart) {
            const temp = selectedPart
            if (temp.stockLocation === undefined) {
                temp.stockLocation = ""
            }
            temp?.notes.push({ note: note, date: moment().format('DD/MM/YYYY') })
            await firebase.firestore().collection("parts").doc(selectedPart?.id).set(temp).then(() => {
                toast.success(`Note added successfully`)
                setNote("")
            })
        }
    }

    return (
        <Screen active="Parts inventory">
            <Modal dialogClassName="dialog-width" show={show} onHide={() => {
                setAdding(false)
                setBom([])
                setNewBom([])
                handleClose()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{adding ? "Add new part" : `Part information: ${selectedPart?.id}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="partInformation">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="partInformation">Part information</Nav.Link>
                                    </Nav.Item>
                                    {!restrictNav &&
                                        <>
                                            <Nav.Item>
                                                <Nav.Link eventKey="billOfMaterials">Bill of materials</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="stockTake">Stock take</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="notes">Notes</Nav.Link>
                                            </Nav.Item>
                                        </>
                                    }
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="partInformation">
                                        <Formik
                                            enableReinitialize
                                            initialValues={{ ...selectedPart, certification: selectedPart?.certification ? selectedPart.certification : "" }}
                                            onSubmit={values => {
                                                if (adding) {
                                                    addPart(values).then(() => {
                                                        getInitialData()
                                                    })
                                                } else {
                                                    editPart(values).then(() => {
                                                        getInitialData()
                                                    })
                                                }
                                            }}

                                        >
                                            {({
                                                handleSubmit,
                                                values,
                                                initialValues,
                                                handleChange
                                            }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Group controlId="part.id">
                                                                <Form.Label>Part number</Form.Label>
                                                                <Form.Control readOnly={!adding} onChange={handleChange}
                                                                    value={values["id"]} name="id" />
                                                            </Form.Group>
                                                            <Form.Group controlId="part.description">
                                                                <Form.Label>Description</Form.Label>
                                                                <Form.Control onChange={handleChange}
                                                                    value={values["description"]}
                                                                    name="description" />
                                                            </Form.Group>
                                                            <Form.Group controlId="part.dimensions">
                                                                <Form.Label>Dimensions</Form.Label>
                                                                <Form.Control onChange={handleChange}
                                                                    value={values["dimensions"]}
                                                                    name="dimensions" />
                                                            </Form.Group>
                                                            <Form.Group controlId="part.material">
                                                                <Form.Label>Material grade</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values.material}
                                                                    name="material">
                                                                    <option>Select an option...</option>
                                                                    <option>{values.material}</option>
                                                                    {dropdowns.materialGrade.map((value) => <option>{value}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="part.certification">
                                                                <Form.Label>Certification</Form.Label>
                                                                <Form.Control value={values["certification"]} as="select" onChange={handleChange}
                                                                    name="certification">
                                                                    <option value="">Select an option...</option>
                                                                    <option value="N/A">N/A</option>
                                                                    <option value="C. of C.">C. of C.</option>
                                                                    <option value="EN 10204 - 2.1">EN 10204 - 2.1</option>
                                                                    <option value="EN 10204 - 2.2">EN 10204 - 2.2</option>
                                                                    <option value="EN 10204 - 3.1">EN 10204 - 3.1</option>
                                                                    <option value="EN 10204 - 3.2">EN 10204 - 3.2</option>
                                                                    <option value="PWHT chart">PWHT chart</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group controlId="part.supplierName">
                                                                <Form.Label>Last supplier name</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values["supplierName"]}
                                                                    name="supplierName">
                                                                    <option>Select an option...</option>
                                                                    <option>{values["supplierName"]}</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="part.price">
                                                                <Form.Label>Last price paid (£)</Form.Label>
                                                                <Form.Control
                                                                    onChange={handleChange}
                                                                    type="Number"
                                                                    value={values["price"] ? parseInt(values["price"].substring(1)) : 0}
                                                                    name="price" />
                                                            </Form.Group>
                                                            <Form.Group controlId="part.stock">
                                                                <Form.Label>Stock</Form.Label>
                                                                <Form.Control type="Number" onChange={handleChange}
                                                                    value={values["stock"]}
                                                                    name="stock" />
                                                            </Form.Group>
                                                            <Form.Group controlId="part.stockLocation">
                                                                <Form.Label>Stock Location</Form.Label>
                                                                <Form.Control onChange={handleChange}
                                                                    value={values["stockLocation"]}
                                                                    name="stockLocation" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Group controlId="part.finish">
                                                                <Form.Label>Finish</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values["finish"]}
                                                                    name="finish">
                                                                    <option>Select an option...</option>
                                                                    <option>{values.finish}</option>
                                                                    {dropdowns.finish.map((value) => <option>{value}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="part.productType">
                                                                <Form.Label>Product category</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values["productType"]}
                                                                    name="productType">
                                                                    <option>Select an option...</option>
                                                                    {dropdowns.productCategory.map((value) => <option>{value}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="part.materialGroup">
                                                                <Form.Label>Material group</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values["materialGroup"]}
                                                                    name="materialGroup">
                                                                    <option>Select an option...</option>
                                                                    {dropdowns.materialGroup.map((value) => <option>{value}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group controlId="part.nominalCodePurchasing">
                                                                <Form.Label>Nominal code purchasing</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values["nominalCodePurchasing"]}
                                                                    name="nominalCodePurchasing">
                                                                    <option>Select an option...</option>
                                                                    {dropdowns.nominalCodes.map((value) => <option>{value}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="part.nominalCodeSales">
                                                                <Form.Label>Nominal code sales</Form.Label>
                                                                <Form.Control as="select" onChange={handleChange}
                                                                    value={values["nominalCodeSales"]}
                                                                    name="nominalCodeSales">
                                                                    <option>Select an option...</option>
                                                                    {dropdowns.nominalCodes.map((value) => <option>{value}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="part.qa">
                                                                <Form.Label>Additional QA requirement</Form.Label>
                                                                <Form.Control name="additionalQA" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row className="mt-3">
                                                        <Col>

                                                        </Col>
                                                        <Col className="d-flex justify-content-end">
                                                            <Button type="submit" className="button-green w-auto">Save
                                                                part information</Button>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="billOfMaterials">
                                        <Formik
                                            enableReinitialize
                                            initialValues={{
                                                partNumber: "",
                                                partQuantity: 0,
                                            }}
                                            onSubmit={values => {
                                                setBillOfMaterials(values)
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                values,
                                                initialValues,
                                                handleChange
                                            }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Group controlId="bill.partNumber">
                                                                <Form.Label>Part number</Form.Label>
                                                                <Form.Control name="partNumber" onChange={handleChange}
                                                                    value={values["partNumber"]}
                                                                    placeholder="Enter part number..." />
                                                            </Form.Group>
                                                            <Form.Group controlId="bill.partQuantity">
                                                                <Form.Label>Quantity</Form.Label>
                                                                <Form.Control name="partQuantity"
                                                                    onChange={handleChange}
                                                                    type="Number"
                                                                    value={values["partQuantity"]}
                                                                    placeholder="Enter quantity..." />
                                                            </Form.Group>
                                                            <div className="w-100 d-flex justify-content-end">
                                                                <Button type="submit" className="button-green w-auto">
                                                                    Add sub part
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            )}
                                        </Formik>
                                        {newBom &&
                                            <hr />
                                        }
                                        {(newBom && selectedPart) && <BOM
                                            deleteLine={(partNumber) => deleteBillRow(partNumber)}
                                            deleteView onClick={(part) => {
                                                console.log(part)
                                                setEditBom(part)
                                                setEditingBom(true)
                                            }} data={newBom} bomRef={selectedPart?.id} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="stockTake">
                                        <Formik
                                            enableReinitialize
                                            initialValues={{ ...selectedPart }}
                                            onSubmit={values => {
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                values,
                                                initialValues,
                                                handleChange
                                            }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Group controlId="stock.number">
                                                                <Form.Label>Stock value</Form.Label>
                                                                <Form.Control type="Number"
                                                                    placeholder="Enter stock value..." />
                                                            </Form.Group>
                                                            <Form.Group className="d-flex flex-column"
                                                                controlId="stock.date">
                                                                <Form.Label>Date</Form.Label>
                                                                <DatePicker
                                                                    dateFormat={"dd/MM/yyyy"}
                                                                    placeholderText="Enter date..."
                                                                    className="form-control"
                                                                    value={stockDate}
                                                                    onChange={(date) => {
                                                                        if (date instanceof Date) {
                                                                            setStockDate(date.toDateString())
                                                                        }
                                                                    }} />
                                                            </Form.Group>
                                                            <div className="w-100 d-flex justify-content-end">
                                                                <Button type="submit" className="button-green w-auto">Submit
                                                                    stock take</Button>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group controlId="stock.exist">
                                                                <Form.Label>Stock take history for this
                                                                    part</Form.Label>
                                                                <Form.Control style={{ height: "25rem" }} as="select"
                                                                    multiple>
                                                                    <option>15 - 15/05/2022</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="notes">
                                        <ul className='notes-list'>
                                            {selectedPart?.notes?.map((value) => {
                                                return (
                                                    <li>{value.date} - {value.note}</li>
                                                )
                                            })}
                                        </ul>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Control value={note} onChange={(e) => setNote(e.currentTarget.value)} placeholder="Enter new note..." as="textarea" rows={5} />
                                        </Form.Group>
                                        <div className="w-100 d-flex justify-content-end">
                                            <Button onClick={() => addNote()} className="button-green w-auto">Add note</Button>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
            <Card>
                <Card.Header
                    className="card-header--sticky purchaseOrders-header d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <MdStore style={{ marginRight: ".3rem" }} size={18} color="#131313" />
                        <p className="font-weight-bold">Parts inventory - Count: {partCount}</p>
                    </div>
                    {!mobileQuery.matches &&
                        < div className="d-flex flex-row align-items-center">
                            <FormControl
                                onKeyDown={(e: any) => e.key === "Enter"}
                                onKeyPress={(a: any) => {
                                    if (a.code === "Enter") {
                                        searchPart()
                                    }
                                }}
                                value={searchText}
                                onChange={(event) => {
                                    setSearchText(event.currentTarget.value)
                                }}
                                style={{ marginLeft: ".3rem", marginRight: ".3rem" }}
                                className="datasheet-list-head__search"
                                placeholder={`Search parts inventory...`}
                            />
                            <Button
                                onClick={() => searchPart()}
                                className="button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                <img src={searchIcon} />
                            </Button>
                        </div>
                    }
                    <div className="d-flex flex-row align-items-center purchaseOrders-header-controls">
                        <a onClick={() => {
                            setAdding(true)
                            setRestrictNav(true)
                            setSelectedPart(undefined)
                            handleShow()
                        }}>
                            <MdNoteAdd size={20} color="#131313" />
                            {` New part`}
                        </a>
                    </div>
                </Card.Header>
                <Card.Body>
                    <InfiniteScroll
                        dataLength={partsList.length}
                        next={() => getData()}
                        hasMore={true}
                        scrollableTarget="sticky-parts-table"
                        loader=""
                    >
                        <TableContainer className={classes.container} id="sticky-parts-table">
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                className="font-weight-bold"
                                                key={column.id}
                                                // @ts-ignore
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partsList.map((row, index) => {
                                        const isSearchedPart = row.id === searchText; // Check if the row is the searched part
                                        return (
                                            <TableRow
                                                ref={isSearchedPart ? searchPartRef : null} // Assign the ref to the searched part's row
                                                onClick={() => {
                                                    console.log(row);
                                                    setSelectedPart(row);
                                                    handleShow();
                                                }}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                {columns.map((column) => {
                                                    // @ts-ignore
                                                    const value = row[column.id];
                                                    return (
                                                        // @ts-ignore
                                                        <TableCell key={column.id} align={column.align}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </InfiniteScroll>
                </Card.Body>
            </Card>
        </Screen >
    )
}

export default Parts