import React, { useEffect, useState } from 'react';
import logo from "../../Images/logo-dark.png";
import Screen from "../../Components/Screen";
import { useHistory } from "react-router";
import { Table, Form, Button } from "react-bootstrap";
import { SalesOrder } from '../Order processing/OrderProcessing';
import { Quote } from '../Enquiry processing/QuotationView';
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import DatePicker from "react-datepicker";
import firebase from "firebase";
import { User } from '../../App';
import { toast } from "react-hot-toast";

export interface DeliveryNote {
    contact: string;
    orderNumber: string;
    commodity: string;
    invoiceNumber: string;
    dateShipped: string;
    dimensions: string;
    weight: string;
    notes: string
}

const DeliveryNote = () => {
    const [users, setUsers] = useState<User[]>([])
    const [contact, setContact] = useState<string>("")
    const [orderNumber, setOrderNumber] = useState<string>("")
    const [commodity, setCommodity] = useState<string>("")
    const [invoiceNumber, setInvoiceNumber] = useState<string>("")
    const [dateShipped, setDateShipped] = useState<string>(moment().format("DD/MM/YYYY"))
    const [dimensions, setDimensions] = useState<string>("")
    const [weight, setWeight] = useState<string>("")
    const [notes, setNotes] = useState<string>("")

    const history = useHistory();
    // @ts-ignore
    const passedState: { so: SalesOrder, quote: Quote } = history.location.state;

    const saveDeliveryNote = async () => {
        // @ts-ignore
        const temp: DeliveryNote = { contact, orderNumber, commodity, invoiceNumber, dateShipped, dimensions, weight, notes };
        console.log(temp)
            const db = firebase.firestore().collection("salesOrders").doc(`SO${passedState.quote.enquiry.tnNumber}`);
            if (temp) {
                await db.update({DN: temp}).then(() => {
                    toast.success(`Delivery note saved successfully`)
                })
            }
            let state = passedState
            passedState.so.DN = temp
            history.push({
                pathname: `/order-processing/delivery-note/view/${passedState.quote.enquiry.tnNumber}`,
                state: { state }
            });
    }

    const getAllUsers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    name: doc.data().name,
                    uid: doc.id,
                    role: doc.data().role,
                    email: doc.data().email,
                    permission: doc.data().permission,
                })
            });
        }).then(() => setUsers(temp))
    }
    useEffect(() => {
        getAllUsers()
    }, [])
    return (
        <Screen active="Delivery note">
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`DELIVERY NOTE`}</h2>
                    <div className="d-flex flex-column mt-4 mb-5">
                        <h5 className="mb-0 purchaseOrdersCreate-template__green-text">Delivery address *</h5>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryTownCity}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryPostCode}</p>
                    </div>
                    <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Therco-Serck reference *</h5>
                                <p>TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}</p>
                            </div>
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Therco-Serck contact *</h5>
                                <Typeahead
                                    onChange={(selected) => {
                                        console.log(selected)
                                        // @ts-ignore
                                        setContact(selected[0].name)
                                    }}
                                    options={users}
                                    labelKey="name"
                                    placeholder="Search for contact..."
                                />
                            </div>
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Customer PO *</h5>
                                <p>{passedState.so.customerPo}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Order number *</h5>
                                <Form.Control
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setOrderNumber(event.target.value)
                                    }}
                                    value={orderNumber}
                                    placeholder="Order number..."
                                />
                            </div>
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Commodity code *</h5>
                                <Form.Control
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setCommodity(event.target.value)
                                    }}
                                    value={commodity}
                                    placeholder="Commodity code..."
                                />
                            </div>
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Invoice number *</h5>
                                <Form.Control
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setInvoiceNumber(event.target.value)
                                    }}
                                    value={invoiceNumber}
                                    placeholder="Invoice number..."
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Shipping date *</h5>
                                <DatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    placeholderText="Enter shipping date..." className="form-control"
                                    value={moment(dateShipped).format("DD/MM/YYYY")}
                                    onChange={(date) => {
                                        if (date instanceof Date) {
                                            setDateShipped(date.toDateString())
                                        }
                                    }} />
                            </div>
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Packed dimensions *</h5>
                                <Form.Control
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDimensions(event.target.value)
                                    }}
                                    value={dimensions}
                                    placeholder="Packed dimensions..."
                                />
                            </div>
                            <div className='d-flex flex-column mb-2'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Total gross weight *</h5>
                                <Form.Control
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setWeight(event.target.value)
                                    }}
                                    value={weight}
                                    placeholder="Gross weight..."
                                />
                            </div>
                        </div>
                    </div>
                    <Table className='mb-5' bordered hover>
                        <thead>
                            <tr>
                                <th className='tbl-qty-w1'>#</th>
                                <th className='tbl-qty-w3'>Quantity</th>
                                <th className='tbl-qty-w4'>Part number</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passedState.so.parts.map((value, index: any) => {
                                console.log(value)
                                return (
                                    <tr>
                                        <td className=''>{index + 1}</td>
                                        <td className=''>{value.quantity}</td>
                                        <td className=''>{value.partNumber}</td>
                                        <td>{value.description}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <div className='mt-5 mb-2 d-flex flex-column'>
                        <h5 className="mb-0 purchaseOrdersCreate-template__green-text">Notes & Special instructions</h5>
                        <Form.Control onChange={(event) => setNotes(event.target.value)} placeholder="Enter special instructions..." as="textarea" rows={5} />
                    </div>
                    <div className='mt-5 mb-5 d-flex flex-column w-100'>
                        <div className='d-flex flex-row justify-content-between w-100'>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Print name:</h5>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Signature:</h5>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Date:</h5>
                        </div>
                    </div>
                    <div className="mt-4">
                        <p><span className="font-weight-bold">Therco-Serck Ltd</span> Rother Valley Way, Holbrook, Sheffield, S20 3RW, United Kingdom</p>
                        <div className="d-flex flex-row">
                            <p className="mr-2"><span className="font-weight-bold">T:</span> 0114 251 0339</p>
                            <p className="mr-2"><span className="font-weight-bold">F:</span> 0114 251 0564</p>
                            <p className="mr-2"><span className="font-weight-bold">E:</span> info@ThercoSerckHTX.com</p>
                            <p><span className="font-weight-bold">W:</span> ThercoSerckHTX.com</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => saveDeliveryNote()}
                        className="button-green mt-5 w-auto">
                        Create delivery note
                    </Button>
                </div>
                </div>
            </div>
        </Screen>
    )
}

export default DeliveryNote