import React, {useEffect, useState} from 'react';
import {Button, Modal, Table} from "react-bootstrap";
import firebase from "firebase";
import BOM from "../../Components/BOM";
import {Link} from "react-router-dom";
import { Enquiry } from '../Enquiry processing/Enquiries';

interface EnquiryEmailModalProps {
    parts: { quantity: number, partNumber: string, description: string, tagNumber: string, date?: string, nominalCode?: string }[]
    show: boolean
    hide: () => void
    tn?: Enquiry
}

const OrderProcessingModal = ({show = false, hide, parts, tn}: EnquiryEmailModalProps) => {
    const [selectedParts, setSelectedParts] = useState<{ partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[]>([])
    const [bom, setBom] = useState<any[]>([])
    const [newBom, setNewBom] = useState<any[]>([])
    const [restart, setRestart] = useState<string>("")

    console.log(parts)

    const handlePartSelect = (value: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }) => {
        if (selectedParts) {
            if (!selectedParts.includes(value)) {
                setSelectedParts([...selectedParts, value])
            }
        } else {
            const temp = [];
            temp.push(value)
            setSelectedParts(temp)
        }
    }

    const getBillOfMaterials = async (partId: string) => {
        const query = firebase.firestore().collection("billPartsLink").doc(partId)
        return await query.get().then((doc) => {
            console.log("Request, Parts, get BOM")
            if (doc.exists) {
                // @ts-ignore
                return (doc.data().subParts)
            }
        })
    }

    const getBillOfMaterialsSubsById = async (id: string, level: number, sequence: number, subSequence: number) => {
        const query = firebase.firestore().collection("billPartsLink").doc(id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, getBOM by ID")
            if (doc.exists) {
                // @ts-ignore
                doc.data().subParts.map(async (subDoc, index) => {
                    const query = firebase.firestore().collection("parts").doc(subDoc.partNumber)
                    await query.get().then((doc) => {
                        // @ts-ignore
                        setBom(bom.concat({
                            ...subDoc,
                            level: (level + 1),
                            sequence: sequence,
                            subSequence: (subSequence + index),
                            // @ts-ignore
                            description: `${doc.data().description}, ${doc.data().dimensions}, ${doc.data().material}`
                        }))
                        checkForSubs(subDoc.partNumber).then((subCheck) => {
                            if (subCheck) {
                                getBillOfMaterialsSubsById(subDoc.partNumber, level + 1, sequence, (subSequence + index))
                            }
                        })
                    })
                })
            }
        })
    }

    const checkForSubs = async (id: string) => {
        const query = firebase.firestore().collection("billPartsLink").doc(id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, Initial data")
            if (doc.exists) {
                return true
            } else {
                return false
            }
        })
    }

    const initiate = async (partId: string) => {
        getBillOfMaterials(partId).then((subParts) => {
            console.log("hit1")
            if (subParts) {
                console.log("hit2")
                subParts.map(async (part: { partNumber: string, partQuantity: number }, index: number) => {
                    const query = firebase.firestore().collection("parts").doc(part.partNumber)
                    await query.get().then((doc) => {
                        console.log("hit3")
                        // @ts-ignore
                        setBom(bom.concat({
                            ...part,
                            level: 1,
                            sequence: index,
                            subSequence: index,
                            // @ts-ignore
                            description: `${doc.data().description}, ${doc.data().dimensions}, ${doc.data().material}`
                        }))
                        checkForSubs(part.partNumber).then((subCheck) => {
                            if (subCheck) {
                                getBillOfMaterialsSubsById(part.partNumber, 1, index, index)
                            }
                        })
                    })
                })
            }
        })
        setRestart("test5")
    }

    useEffect(() => {
        if (bom.length === 0) {
            setNewBom(bom)
        } else {
            setNewBom(newBom.concat(...bom))
        }
    }, [bom])

    // @ts-ignore
    useEffect(() => {
        parts.map((value: any, index: any, array: any) => {
            initiate(value.partNumber)
        })
    }, [parts])

    return (
        <Modal dialogClassName="large-dialog" show={show} onHide={() => {
            setSelectedParts([])
            setBom([])
            setNewBom([])
            hide()
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{`Select parts for purchase order`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="form-label">Select line items for purchase order:</p>
                {parts.map((value: any, index: any, array: any) => {
                    return (
                        <BOM topLevelQty={value.quantity} explodedProp hideButtons data={newBom} bomRef={value.partNumber} onClick={(part) => handlePartSelect({...part, partQuantity: parseInt(value.quantity) * part.partQuantity})}/>
                    )
                })}
                {selectedParts &&
                <>
                    <p className="form-label">Items selected for purchase order:</p>
                    <Table className="mb-4" bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Part number</th>
                            <th>Description</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedParts.map((value: any, index: any, array: any) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{value.partNumber}</td>
                                    <td>{value.description}</td>
                                    <td>{value.partQuantity}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </>
                }
            </Modal.Body>
            <Modal.Footer>
                {selectedParts.length > 0 &&
                <Link
                    to={{
                        pathname: `/purchase-orders/create`,
                        state: {
                            parts: selectedParts,
                            soNumber: tn?.tnNumber || ""
                        }
                    }}
                    className="button-green w-auto ml-2">
                    Create purchase order
                </Link>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default OrderProcessingModal