import React, {useContext, useEffect, useState} from 'react';
import Screen from "../../Components/Screen";
import {Link, useHistory} from "react-router-dom";
import {Accordion, Button, Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import {MdAdd, MdAssignment, MdAvTimer, MdHelp, MdNoteAdd, MdRefresh, MdRemove} from "react-icons/md";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import firebase from "firebase";
import {PurchaseOrder} from "./PurchaseOrdersCreate";
import ThercoDropdown from "../../Components/ThercoDropdown";
import {globalUser, User} from "../../App";
import moment from "moment";
import {GoPrimitiveDot} from "react-icons/all";
import InfiniteScroll from "react-infinite-scroll-component";

const PurchaseOrders = () => {
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>()
    const [userPendings, setUserPendings] = useState<PurchaseOrder[]>()
    const [data, setData] = useState<any>([])
    const [recents, setRecents] = useState<PurchaseOrder[]>()
    const [acitveKey, setActiveKey] = useState<number>()
    const [searchType, setSearchType] = useState<string>("PO Number")
    const [searchText, setSearchText] = useState<string>("")
    const [approver, setApprover] = useState<User>()
    const [raisedBy, setRaisedBy] = useState<User>()
    const history = useHistory();
    const currentUser = useContext(globalUser)

    const getUserPendings = async () => {
        let temp: PurchaseOrder[] = []
        const query = firebase.firestore().collection('purchaseOrders')
            .where("approverId", "==", currentUser.currentUser.uid)
            .where("approved", "==", false)
            .orderBy("id", "desc")
            .limit(4)

        const userPendings = await query.get()
        userPendings.docs.map((doc) => {
            // @ts-ignore
            temp.push(doc.data())
        })
        setUserPendings(temp)
    }

    const getRecents = async () => {
        let temp: PurchaseOrder[] = []
        const query = firebase.firestore().collection('purchaseOrders')
            .where("raisedById", "==", currentUser.currentUser.uid)
            .orderBy("id", "desc")
            .limit(4)

        const userPendings = await query.get()
        userPendings.docs.map((doc) => {
            // @ts-ignore
            temp.push(doc.data())
        })
        setRecents(temp)
    }

    const getPurchaseOrders = async () => {
        let temp: PurchaseOrder[] = []
        const query = firebase.firestore().collection('purchaseOrders')
            .orderBy('id', "desc")
            .limit(30)

        const purchaseOrders = await query.get()
        purchaseOrders.docs.map((doc) => {
            // @ts-ignore
            temp.push(doc.data())
        })
        setPurchaseOrders(temp)
        setData(purchaseOrders)
    }

    const getMorePurchaseOrders = async () => {
        if (purchaseOrders) {
            let temp: PurchaseOrder[] = []
            const lastVisible = data && data.docs[data.docs.length - 1]
            const query = firebase.firestore().collection('purchaseOrders')
                .orderBy('id', "desc")
                .startAfter(lastVisible)
                .limit(30)

            const purchaseOrdersDocs = await query.get()
            purchaseOrdersDocs.docs.map((doc) => {
                // @ts-ignore
                temp.push(doc.data())
            })
            setPurchaseOrders([...purchaseOrders, ...temp])
            setData(purchaseOrdersDocs)
        }
    }

    const getApprover = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setApprover({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const getRaisedBy = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setRaisedBy({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const purchaseOrderSearch = async () => {
        let temp: PurchaseOrder[] = []
        let query = firebase.firestore().collection('purchaseOrders')
            .where("id", "==", parseInt(searchText))
        if (searchType === "supplier name") {
            query = firebase.firestore().collection('purchaseOrders')
                .where("supplier.name", "==", searchText)
        }

        await query.get().then((snapshot) => {
            snapshot.forEach((doc) => {
                // @ts-ignore
                temp.push(doc.data())
            })
        })
        setPurchaseOrders(temp)
    }

    useEffect(() => {
        getPurchaseOrders()
        getUserPendings()
        getRecents()
    }, [])

    useEffect(() => {
        getPurchaseOrders()
    }, [searchText])

    const mobileQuery = window.matchMedia('(max-width: 850px)')

    return (
        <Screen active="Purchase orders">
            <Card>
                <Card.Header className="purchaseOrders-header d-flex justify-content-between align-items-center card-header--sticky">
                    <div className="d-flex flex-row align-items-center">
                        <MdAssignment style={{marginRight: ".3rem"}} size={18} color="#131313"/>
                        <p className="font-weight-bold">Purchase orders</p>
                    </div>
                    <div className="d-flex flex-row align-items-center purchaseOrders-header-controls">
                        <a onClick={() => history.push("/purchase-orders/create")}>
                            <MdNoteAdd size={20} color="#131313"/>
                            {` New purchase order`}
                        </a>
                    </div>
                </Card.Header>
                <Card.Body className="purchaseOrders-body">
                    {purchaseOrders &&
                    <InfiniteScroll
                        dataLength={purchaseOrders.length}
                        next={() => getMorePurchaseOrders()}
                        hasMore={true}
                        scrollableTarget="screen-container"
                        loader=""
                    >
                        <Container fluid={true}>
                            {!mobileQuery.matches && 
                            <Row noGutters className="border-bottom pb-3">
                                <Col md={9}>
                                    {(currentUser.currentUser.permission === "Level 1" && userPendings && userPendings?.length > 0) ?
                                        <Card>
                                            <Card.Header
                                                className="purchaseOrders-header d-flex justify-content-between align-items-center">
                                                <div className="d-flex flex-row align-items-center">
                                                    <MdAvTimer style={{marginRight: ".3rem"}} size={18}
                                                               color="#131313"/>
                                                    <p className="font-weight-bold">Purchase orders awaiting your
                                                        approval</p>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <Table  bordered hover>
                                                    <thead>
                                                    <tr>
                                                        <th>PO Number</th>
                                                        <th>Supplier</th>
                                                        <th>No. Parts</th>
                                                        <th>Total parts</th>
                                                        <th>Price (Excluding VAT)</th>
                                                        <th>Price (Including VAT)</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {userPendings?.map((po) => {
                                                        console.log(po)
                                                        let total = 0
                                                        let size = 0
                                                        po.parts.map((part) => {
                                                            total += part.price
                                                            size += part.quantity
                                                        })
                                                        return (
                                                            <tr onClick={() => history.push(`/purchase-orders/view/${po.id}`)}>
                                                                <td>{po.id}</td>
                                                                <td>{po.supplier?.name}</td>
                                                                <td className="text-right">{po.parts.length}</td>
                                                                <td className="text-right">{size}</td>
                                                                <td className="text-right">£{total.toFixed(2)}</td>
                                                                <td className="text-right">£{(((total / 100) * 20) + total).toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                        :
                                        <Card>
                                            <Card.Header
                                                className="purchaseOrders-header d-flex justify-content-between align-items-center">
                                                <div className="d-flex flex-row align-items-center">
                                                    <MdAvTimer style={{marginRight: ".3rem"}} size={18}
                                                               color="#131313"/>
                                                    <p className="font-weight-bold">Your most recent purchase orders</p>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <Table  bordered hover>
                                                    <thead>
                                                    <tr>
                                                        <th>PO Number</th>
                                                        <th>Supplier</th>
                                                        <th>No. Parts</th>
                                                        <th>Total parts</th>
                                                        <th>Price (Excluding VAT)</th>
                                                        <th>Approval status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {recents?.map((po) => {
                                                        let total = 0
                                                        let size = 0
                                                        po.parts.map((part) => {
                                                            total += parseInt(part.part.price)
                                                            size += part.quantity
                                                        })
                                                        return (
                                                            <tr onClick={() => history.push(`/purchase-orders/view/${po.id}`)}>
                                                                <td>{po.id}</td>
                                                                <td>{po.supplier?.name}</td>
                                                                <td className="text-right">{po.parts.length}</td>
                                                                <td className="text-right">{size}</td>
                                                                <td className="text-right">£{total.toFixed(2)}</td>
                                                                <td>
                                                                <span
                                                                    className={`tag ${po.approved ? 'tag--green' : 'tag--orange'}`}>
                                                                    {po.approved ? 'Approved' : 'Awaiting approval'}
                                                                </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    }
                                    <Form className="pt-3 border-top mt-3">
                                        <Form.Group className="d-flex flex-row align-items-center mb-0">
                                            <Form.Control onChange={(event) => setSearchType(event.currentTarget.value)}
                                                          className="w-25" as="select">
                                                <option value="PO number">PO Number</option>
                                                <option value="supplier name">Supplier Name</option>
                                            </Form.Control>
                                            <Form.Control
                                                onChange={(event) => setSearchText(event.currentTarget.value)}
                                                value={searchText}
                                                style={{marginLeft: ".3rem", marginRight: ".3rem"}}
                                                placeholder={`Search purchase orders by ${searchType}...`}/>
                                            <Button
                                                onClick={() => purchaseOrderSearch()}
                                                className="button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                <img src={searchIcon}/>
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col className="pl-3" md={3}>
                                    <Card className="h-100">
                                        <Card.Header
                                            className="purchaseOrders-header d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-row align-items-center">
                                                <MdHelp style={{marginRight: ".3rem"}} size={18} color="#131313"/>
                                                <p className="font-weight-bold">Helpful documentation</p>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="purchaseOrders-body">
                                            <ul className="d-flex flex-column">
                                                <li className="mb-2">
                                                    <Link to="/" className="d-flex align-items-center flex-row">
                                                        <GoPrimitiveDot className="mr-1" color="#131313"
                                                                        size={16}/> Searching for a purchase order
                                                    </Link>
                                                </li>
                                                <li className="mb-2">
                                                    <Link to="/" className="d-flex align-items-center flex-row">
                                                        <GoPrimitiveDot className="mr-1" color="#131313" size={16}/> How
                                                        to
                                                        raise a purchase order
                                                    </Link>
                                                </li>
                                                <li className="mb-2">
                                                    <Link to="/" className="d-flex align-items-center flex-row">
                                                        <GoPrimitiveDot className="mr-1" color="#131313"
                                                                        size={16}/> Viewing
                                                        a purchase order
                                                    </Link>
                                                </li>
                                                <li className="mb-2">
                                                    <Link to="/" className="d-flex align-items-center flex-row">
                                                        <GoPrimitiveDot className="mr-1" color="#131313"
                                                                        size={16}/> Approving a purchase order
                                                    </Link>
                                                </li>
                                                <li className="mb-2">
                                                    <Link to="/" className="d-flex align-items-center flex-row">
                                                        <GoPrimitiveDot className="mr-1" color="#131313"
                                                                        size={16}/> Saving
                                                        a purchase order as a PDF
                                                    </Link>
                                                </li>
                                                <li className="mb-2">
                                                    <Link to="/" className="d-flex align-items-center flex-row">
                                                        <GoPrimitiveDot className="mr-1" color="#131313"
                                                                        size={16}/> Editing
                                                        a purchase order
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
}
                        </Container>
                        <div className="d-flex flex-column p-3">
                            <Accordion>
                                {purchaseOrders?.map((po, index) => {
                                    let beforeVAT = 0;
                                    po.parts.map((part) => {
                                        beforeVAT += (parseInt(part.part.price) * part.quantity)
                                    })
                                    return (
                                        <Card>
                                            <Accordion.Toggle
                                                onClick={() => {
                                                    if (acitveKey === index) {
                                                        setActiveKey(undefined)
                                                    } else {
                                                        setActiveKey(index)
                                                        getRaisedBy(po.raisedById)
                                                        getApprover(po.approverId)
                                                    }
                                                }}
                                                className="purchaseOrders-body-accordion-header"
                                                as={Card.Header} eventKey={index.toString()}>
                                                {`PO ${po.id}`}
                                                {`    -    ${po.supplier && po.supplier.name}`}
                                                {acitveKey === index ? <MdRemove size={20}/> : <MdAdd size={20}/>}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={index.toString()}>
                                                <Card.Body>
                                                    <div className={`d-flex w-100 justify-content-between mb-3 ${mobileQuery.matches && "flex-column"}`}>
                                                        <div className="d-flex flex-column">
                                                            <p className="d-flex flex-column mb-2">
                                                                <span className="font-weight-bold">Date raised: </span>
                                                                {po.dateRaised}
                                                            </p>
                                                            <p className="d-flex flex-column mb-2">
                                                            <span
                                                                className="font-weight-bold">Price (Before VAT): </span>
                                                                £{beforeVAT.toFixed(2)}
                                                            </p>
                                                            <p className="d-flex flex-column mb-2">
                                                            <span
                                                                className="font-weight-bold">Price (After VAT): </span>
                                                                £{(((beforeVAT / 100) * 10) + beforeVAT).toFixed(2)}
                                                            </p>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <p className="font-weight-bold">Supplier information:</p>
                                                            <p>{po.supplier?.name}</p>
                                                            <p>{po.supplier?.addressOne}</p>
                                                            <p>{po.supplier?.townCity}</p>
                                                            <p>{po.supplier?.postCode}</p>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <p className="font-weight-bold">Supplier contact:</p>
                                                            <p>{po.supplierContact?.name}</p>
                                                            <p>{po.supplierContact?.position}</p>
                                                            <a href={`mailto:${po.supplierContact?.email}`}>{po.supplierContact?.email}</a>
                                                            <a href={`tel:${po.supplierContact?.directPhone}`}>{po.supplierContact?.directPhone}</a>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <p className="d-flex flex-column mb-2">
                                                                <span
                                                                    className="font-weight-bold">Approval status: </span>
                                                                {po.approved ?
                                                                    <span className="tag tag--green">Approved</span>
                                                                    :
                                                                    <span
                                                                        className="tag tag--orange">Awaiting approval</span>
                                                                }
                                                            </p>
                                                            <p className="d-flex flex-column mb-2">
                                                                <span className="font-weight-bold">Created by: </span>
                                                                <Link to={`/accounts/${raisedBy?.uid}`}>
                                                                    {raisedBy?.name}
                                                                </Link>
                                                            </p>
                                                            <p className="d-flex flex-column mb-4">
                                                    <span className="font-weight-bold">
                                                        {po.approved ? "Approved by" : "Awaiting approval from"}:
                                                    </span>
                                                                <Link to={`/accounts/${approver?.uid}`}>
                                                                    {approver?.name}
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <ThercoDropdown initialOpen={true}
                                                                    title={`Parts included: (${po.parts.length})`}>
                                                        <Table  bordered hover>
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Part ID</th>
                                                                <th className="w-75">Description, dimension, material & finish</th>
                                                                <th>Due date</th>
                                                                <th>Price</th>
                                                                <th>Quantity</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {po.parts.map((part, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{part.part.id}</td>
                                                                        <td>{part.part.description}, {part.part.dimensions}, {part.part.material}, {part.part.finish}</td>
                                                                        <td>{moment(new Date(part.dueDate)).format("DD/MM/YYYY")}</td>
                                                                        <td className="text-right">£{part.price}.00</td>
                                                                        <td className="text-right">{part.quantity}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </tbody>
                                                        </Table>
                                                    </ThercoDropdown>
                                                    <div className={`mt-4 d-flex justify-content-end flex-row ${mobileQuery.matches && "flex-column"}`}>
                                                        {!po.approved &&
                                                        <Link to={`/purchase-orders/edit/${po.id}`}>
                                                            <Button className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2 w-100"}`}>
                                                                Edit purchase order
                                                            </Button>
                                                        </Link>
                                                        }
                                                        <Link to={`/purchase-orders/view/${po.id}`}>
                                                            <Button className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2 w-100"}`}>
                                                                View purchase order
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )
                                })}
                            </Accordion>
                        </div>
                    </InfiniteScroll>
                    }
                </Card.Body>
            </Card>
        </Screen>
    )
}

export default PurchaseOrders