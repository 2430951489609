import firebase from 'firebase';
import React, {useState} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from "./Pages/Dashboard";
import Enquiries from "./Pages/Enquiry processing/Enquiries";
import Parts from "./Pages/Parts";
import PurchaseOrdersCreate from "./Pages/Purchase Orders/PurchaseOrdersCreate";
import PurchaseOrdersView from "./Pages/Purchase Orders/PurchaseOrdersView";
import PurchaseOrders from "./Pages/Purchase Orders/PurchaseOrders";
import PurchaseOrdersEdit from "./Pages/Purchase Orders/PurchaseOrdersEdit";
import LiveUpdates from "./Components/LiveUpdates";
import ControlPanel from "./Pages/ControlPanel";
import CreateBomDocument from "./Pages/BOM/CreateBomDocument";
import BomTemplateSelection from "./Pages/BOM/BomTemplateSelection";
import EngineeringBOM from "./Pages/BOM/EngineeringBOM";
import QuotationCreate from "./Pages/Enquiry processing/QuotationCreate";
import QuotationView from "./Pages/Enquiry processing/QuotationView";
import OrderProcessing from "./Pages/Order processing/OrderProcessing";
import OrderAcknowledgement from "./Pages/Order processing/OrderAcknowledgement";
import GoodsIn from "./Pages/Goods in/GoodsIn";
import Reports from "./Pages/Reports/Reports";
import TotalStockList from "./Pages/Reports/Templates/TotalStockList";
import DeliveryNote from "./Pages/Delivery Note/DeliveryNote";
import GoodsRecievedMonth from "./Pages/Reports/Templates/GoodsRecievedMonth";
import SoClosedInMonth from "./Pages/Reports/Templates/SoClosedInMonth";
import StockListNoSo from "./Pages/Reports/Templates/StockListNoSo";
import TotalStockBySo from "./Pages/Reports/Templates/TotalStockBySo";
import ViewOrderAcknowledgement from './Pages/Order processing/ViewOrderAcknowledgement';
import DeliveryNoteView from './Pages/Delivery Note/DeliveryNoteView';
import Invoice from './Pages/Order processing/SalesInvoice';
import InvoiceView from './Pages/Order processing/SalesInvoiceView';
import PartsBySo from './Pages/Reports/Templates/PartsBySo';
import MaterialsNotReceived from './Pages/Reports/Templates/MaterialsNotReceived';
import SalesOrderIntake from './Pages/Reports/Templates/SalesOrderIntake';
import JobsClosedInMonth from './Pages/Reports/Templates/JobsClosedInMonth';

export interface User {
    uid: string;
    name: string;
    email: string;
    role: string;
    permission: string;
}

export interface Notification {
    type: string;
    text: string;
    link: string;
    dateTime: string;
    viewed: boolean;
}

export const globalUser = React.createContext({
    currentUser: {uid: "", name: "", email: "", permission: "", role: ""}, setCurrentUser: (user: User) => {
    }
});

export const globalNotifications = React.createContext({
    allNotifications: [{type: "", text: "", link: "", dateTime: "", viewed: true}],
    setAllNotifications: (notification: Notification[]) => {
    }
});

function App() {
    const [currentUser, updateCurrentUser] = useState<User>({
        uid: "",
        name: "",
        email: "",
        permission: "",
        role: "",
    })

    const [allNotifications, updateAllNotifications] = useState<Notification[]>([])

    function setCurrentUser(user: User) {
        updateCurrentUser(user);
    }

    function setAllNotifications(notifications: Notification[]) {
        updateAllNotifications(notifications);
    }

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    var firebaseConfig = {
        apiKey: "AIzaSyAAYML41asLy0XWuUhMzxoqFsxf7Ow-Bvc",
        authDomain: "therco-intranet.firebaseapp.com",
        databaseURL: "https://therco-intranet-default-rtdb.firebaseio.com",
        projectId: "therco-intranet",
        storageBucket: "therco-intranet.appspot.com",
        messagingSenderId: "1010384065483",
        appId: "1:1010384065483:web:5f534a1004995fd8809c4e"
    };
    // Initialize Firebase
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()

    return (
        <globalUser.Provider value={{currentUser, setCurrentUser}}>
            <globalNotifications.Provider value={{allNotifications, setAllNotifications}}>
                <LiveUpdates>
                    <BrowserRouter>
                        <Route exact path="/">
                            <Login/>
                        </Route>
                        <Route exact path="/order-processing">
                            <OrderProcessing/>
                        </Route>
                        <Route exact path="/order-processing/delivery-note/:id">
                            <DeliveryNote/>
                        </Route>
                        <Route exact path="/order-processing/delivery-note/view/:id">
                            <DeliveryNoteView/>
                        </Route>
                        <Route exact path="/order-processing/sales-invoice/:id">
                            <Invoice/>
                        </Route>
                        <Route exact path="/order-processing/sales-invoice/view/:id">
                            <InvoiceView/>
                        </Route>
                        <Route exact path="/order-processing/order-acknowledgement/:id">
                            <OrderAcknowledgement/>
                        </Route>
                        <Route exact path="/order-processing/order-acknowledgement/view/:id">
                            <ViewOrderAcknowledgement/>
                        </Route>
                        <Route exact path="/dashboard">
                            <Dashboard/>
                        </Route>
                        <Route exact path="/enquiries">
                            <Enquiries/>
                        </Route>
                        <Route exact path="/enquiries/quotations/create/:id">
                            <QuotationCreate/>
                        </Route>
                        <Route exact path="/enquiries/quotations/view/:id">
                            <QuotationView/>
                        </Route>
                        <Route exact path="/parts-inventory">
                            <Parts/>
                        </Route>
                        <Route exact path="/purchase-orders">
                            <PurchaseOrders/>
                        </Route>
                        <Route exact path="/purchase-orders/create">
                            <PurchaseOrdersCreate/>
                        </Route>
                        <Route exact path="/purchase-orders/view/:id">
                            <PurchaseOrdersView/>
                        </Route>
                        <Route exact path="/purchase-orders/edit/:id">
                            <PurchaseOrdersEdit/>
                        </Route>
                        <Route exact path="/goods-in">
                            <GoodsIn/>
                        </Route>
                        <Route exact path="/reports">
                            <Reports/>
                        </Route>
                        <Route exact path="/reports/total-stock-list">
                            <TotalStockList/>
                        </Route>
                        <Route exact path="/reports/sales-order-intake">
                            <SalesOrderIntake/>
                        </Route>
                        <Route exact path="/reports/jobs-closed-in-month">
                            <JobsClosedInMonth/>
                        </Route>
                        <Route exact path="/reports/materials-not-received">
                            <MaterialsNotReceived/>
                        </Route>
                        <Route exact path="/reports/stock-by-SO">
                            <TotalStockBySo/>
                        </Route>
                        <Route exact path="/reports/parts-by-SO">
                            <PartsBySo/>
                        </Route>
                        <Route exact path="/reports/stock-no-SO">
                            <StockListNoSo/>
                        </Route>
                        <Route exact path="/reports/SO-closed-in-a-month">
                            <SoClosedInMonth/>
                        </Route>
                        <Route exact path="/reports/goods-received">
                            <GoodsRecievedMonth/>
                        </Route>
                        <Route exact path="/control-panel">
                            <ControlPanel/>
                        </Route>
                        <Route exact path="/create-bill-of-materials">
                            <BomTemplateSelection/>
                        </Route>
                        <Route exact path="/create-bill-of-materials/indented-parts-list">
                            <CreateBomDocument/>
                        </Route>
                        <Route exact path="/create-bill-of-materials/engineering-bom">
                            <EngineeringBOM/>
                        </Route>
                    </BrowserRouter>
                </LiveUpdates>
            </globalNotifications.Provider>
        </globalUser.Provider>
    );
}

export default App;
