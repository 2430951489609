import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import { useParams, useHistory } from 'react-router-dom';
import moment from "moment";
import { toast } from "react-hot-toast";
import { Form, Table, Button } from "react-bootstrap";
import { SalesOrder } from "./OrderProcessing";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { SupplierContact } from '../ControlPanel';

export interface SalesInvoice {
    contact: string;
    taxDate: string;
}

const Invoice = () => {
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [contact, setContact] = useState<string>("")
    const [taxDate, setTaxDate] = useState<string>(moment().format("DD/MM/YYYY"))
    const [customerContacts, setCustomerContacts] = useState<SupplierContact[]>([])
    const [selectedSupplierContact, setSelectedSupplierContact] = useState<SupplierContact | undefined>()
    const history = useHistory();
    // @ts-ignore
    let { id } = useParams();

    const getSalesOrder = async () => {
        const query = firebase.firestore().collection('salesOrders').doc(`SO${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setSalesOrder(doc.data())
        })
    }

    useEffect(() => {
        getSalesOrder()
    }, [])

    useEffect(() => {
        getCustomerContacts()
    }, [salesOrder])

    let total = 0

    if (salesOrder) {
        salesOrder.parts.map((value, index) =>
            total = total + (salesOrder.quote.itemPrices[index].price * value.quantity)
        );
    }

    const saveSalesInvoice = async () => {
        // @ts-ignore
        const temp: SalesInvoice = {contact, taxDate};
        const db = firebase.firestore().collection("salesOrders").doc(`SO${id}`);
        if (temp) {
            await db.update({ SI: temp }).then(() => {
                toast.success(`Sales invoice saved successfully`)
                history.push(`/order-processing/sales-invoice/view/${id}`)
            })
        }
    }


    const getCustomerContacts = async () => {
        const db = firebase.firestore().collection("customers");
        let temp: SupplierContact[] = []
        if(salesOrder) {
            db.doc(salesOrder.quote.enquiry.customer.id).get()
            .then((doc) => {
                console.log(doc.data())
                //@ts-ignore
                if (doc.data().contacts !== undefined) {
                    //@ts-ignore
                    doc.data().contacts.map((item) => {
                        temp.push({
                            id: item.id,
                            // @ts-ignore
                            name: item.name,
                            // @ts-ignore
                            email: item.email,
                            // @ts-ignore
                            position: item.position,
                            // @ts-ignore
                            directPhone: item.directPhone,
                            // @ts-ignore
                            mobilePhone: item.mobilePhone,
                        })
                    })
                }
            }).then(() => setCustomerContacts(temp))
        }
    }

    return (
        <Screen active="Order acknowledgement">
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <h3 className="green-section-header mt-3">INVOICE NO. {id}SI1</h3>
                        <p className="green-section-header font-weight-bold mt-1">CUSTOMER DETAILS:</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.name}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.addressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.addressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.addressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.townCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.postCode}</p>
                    </div>
                </div>
                <div className='d-flex flex-row mt-5 justify-content-between'>
                    <div className='d-flex flex-column'>
                    <p className="green-section-header font-weight-bold">FAO:</p>
                    {selectedSupplierContact ?
                                <p>{`${selectedSupplierContact.name}`}</p>
                                :
                                <div className="purchaseOrdersCreate-template__textbox">
                                    {customerContacts &&
                                        <Typeahead
                                            onChange={(selected) => {
                                                setSelectedSupplierContact(selected[0])
                                            }}
                                            placeholder='Select a contact...'
                                            options={customerContacts}
                                            labelKey={(option) => `${option.name}`}
                                        />
                                    }
                                </div>
                            }
                    </div>
                    <div className='d-flex flex-column'>
                    <p className="green-section-header font-weight-bold">YOUR PO NO:</p>
                        <p>{salesOrder && salesOrder?.customerPo}</p>
                    </div>
                    <div className='d-flex flex-column'>
                    <p className="green-section-header font-weight-bold">TAX DATE:</p>
                    <DatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    placeholderText="Enter tax date..." className="form-control"
                                    value={moment(taxDate).format("DD/MM/YYYY")}
                                    onChange={(date) => {
                                        if (date instanceof Date) {
                                            setTaxDate(date.toDateString())
                                        }
                                    }} />
                    </div>
                </div>
                <h5 className="green-section-header mt-5">SECTION 1.0 - QUANTITY, DESCRIPTION & PRICE</h5>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th className="text-right tbl-qty-w1">Line</th>
                            <th className="text-right tbl-qty-w1">QTY</th>
                            <th className='tbl-qty-w4'>Part number</th>
                            <th>Description</th>
                            <th className="text-right tbl-qty-w3">Unit price</th>
                            <th className="text-right tbl-qty-w3">Line price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrder && salesOrder.parts.map((value, index) => {


                            return (
                                <tr>
                                    <td className="text-right">{index + 1}</td>
                                    <td className="text-right">{value.quantity}</td>
                                    <td className="">{value.partNumber}</td>
                                    <td>{value.description}</td>
                                    <td className="text-right">£{salesOrder.quote.itemPrices[index].price.toFixed(2)}</td>
                                    <td className="text-right">£{(salesOrder.quote.itemPrices[index].price * value.quantity).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                       {salesOrder?.OA?.delivery &&
                                                <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='font-weight-bold text-right'>Delivery</td>
                                                <td className='small-qty text-right'>
                                                    £{salesOrder?.OA?.delivery.toFixed(2)}
                                                </td>
                                            </tr>
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Sub total</td>
                            <td className='small-qty text-right'>
                                £{salesOrder?.OA?.delivery ? (total + salesOrder?.OA?.delivery).toFixed(2) : total.toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>VAT</td>
                            <td className='small-qty text-right'>
                                £{salesOrder?.OA?.delivery ? ((total + salesOrder?.OA?.delivery) / 100 * 20).toFixed(2) : (total /100 * 20).toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Total</td>
                            <td className="text-right">
                                £{salesOrder?.OA?.delivery ? ((salesOrder?.OA?.delivery + total) + (total + salesOrder?.OA?.delivery) / 100 * 20).toFixed(2) : (total + (total / 100 * 20)).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </Table>


                <h5 className="green-section-header mt-5">IMPORTANT NOTE</h5>
                <p>Therco – Serck Terms and Conditions of Sale (available on request) apply to this sale. For the avoidance of doubt, unless agreed
                    otherwise our payment terms are strictly 30 days after the end of the month in which an invoice was raised.
                </p>
                <br/>
                <p className='font-weight-bold'>Payment to be made to the following bank account:</p>
                <p>Lloyds Bank plc</p>
                <p>14 Church Street</p>
                <p>Sheffield</p>
                <p>S1 1HP</p>
                <br />
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row align-items-center'>
                        <p className="green-section-header font-weight-bold mr-2">ACCOUNT NAME:</p>
                        <p>Therco-Serck Ltd</p>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <p className="green-section-header font-weight-bold mr-2">ACCOUNT NO:</p>
                        <p>66135968</p>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row align-items-center'>
                        <p className="green-section-header font-weight-bold mr-2">IBAN:</p>
                        <p>GB19LOYD30975166135968</p>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <p className="green-section-header font-weight-bold mr-2">BIC:</p>
                        <p>LOYDGB21040</p>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <p className="green-section-header font-weight-bold mr-2">SORT CODE:</p>
                        <p>30-97-51</p>
                    </div>
                </div>
                <div className="mt-4">
                    <p><span className="font-weight-bold">Therco-Serck Ltd</span> Rother Valley Way, Holbrook, Sheffield, S20 3RW, United Kingdom</p>
                    <div className="d-flex flex-row">
                        <p className="mr-2"><span className="font-weight-bold">T:</span> 0114 251 0339</p>
                        <p className="mr-2"><span className="font-weight-bold">F:</span> 0114 251 0564</p>
                        <p className="mr-2"><span className="font-weight-bold">E:</span> info@ThercoSerckHTX.com</p>
                        <p><span className="font-weight-bold">W:</span> ThercoSerckHTX.com</p>
                    </div>
                </div>
                <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => saveSalesInvoice()}
                        className="button-green mt-5 w-auto">
                        Create sales invoice
                    </Button>
                </div>
            </div>
        </Screen>
    )
}

export default Invoice