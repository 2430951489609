import React, { useContext, useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from '../../Images/logo-dark.png'
import moment from "moment";
import firebase from "firebase";
import { useHistory, useParams } from "react-router";
import { Enquiry } from "./Enquiries";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { globalUser, Notification, User } from "../../App";
import { MdAdd, MdEdit, MdFormatListBulleted } from "react-icons/md";

const QuotationCreate = () => {
    const history = useHistory();
    const [enquiry, setEnquiry] = useState<Enquiry>()
    const [switchTwo, setSwitchTwo] = useState<boolean>(false)
    const [switchTwoVal, setSwitchTwoVal] = useState<string>("")
    const [switchFive, setSwitchFive] = useState<boolean>(false)
    const [switchFiveVal, setSwitchFiveVal] = useState<string>("")
    const [itemPrices, setItemPrices] = useState<{ price: number, index: number }[]>([])
    const [singlePrices, setSinglePrices] = useState<number[]>([0, 0])
    const [approvers, setApprovers] = useState<User[]>([]);
    const [show, setShow] = useState(false);
    const [showExtra, setShowExtra] = useState(false);
    const [selectedApprover, setSelectedApprover] = useState<string>("")
    const [selectedCurrency, setSelectedCurrency] = useState<string>("GBP")
    const [selectedCurrencyIcon, setSelectedCurrencyIcon] = useState<string>("£")
    const [deliveryText, setDeliveryText] = useState<string>("");
    const [dropdowns, setDropdowns] = useState({
        sectionTwo: [],
        sectionFive: [],
        sectionSix: [],
        sectionSeven: [],
        sectionEight: [],
        sectionNine: [],
        sectionTen: []
    })
    const [section2, setSection2] = useState<string[]>([])
    const [section2Value, setSection2Value] = useState<string>("")
    const [section5, setSection5] = useState<string[]>([])
    const [section5Value, setSection5Value] = useState<string>("")
    const [section6, setSection6] = useState<boolean>(false)
    const [section7, setSection7] = useState<boolean>(false)
    const [section8, setSection8] = useState<boolean>(false)
    const [section9, setSection9] = useState<boolean>(false)
    const [section10, setSection10] = useState<boolean>(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleExtraClose = () => setShowExtra(false);
    const handleExtraShow = () => setShowExtra(true);
    // @ts-ignore
    let { id } = useParams();
    const currentUser = useContext(globalUser)

    const getEnquiry = async () => {
        const query = firebase.firestore().collection('enquiries').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setEnquiry(doc.data())
        })
    };

    const handlePriceChange = (item: { price: string, index: number }) => {
        const itemPrice = parseInt(item.price)
        const arr = itemPrices.map((value) => {
            if (value.index === item.index) {
                value.price = itemPrice
            }
            return value
        })
        setItemPrices(arr)
    }

    const getApprovers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.where("permission", "==", "Level 1").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                if (doc.exists)
                    temp.push({
                        role: doc.data().role,
                        permission: doc.data().permission,
                        uid: doc.id,
                        email: doc.data().email,
                        name: doc.data().name
                    })
            })
        }).then(() => setApprovers(temp)).then(() => setSelectedApprover(temp[0].uid))
    }

    const getDropdowns = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("sections");
        await db.get().then((snap) => {
            if (snap.exists) {
                // @ts-ignore
                setDropdowns(snap.data().values)
            }
        })
    }

    const handleQuoteSubmit = async () => {
        if (enquiry) {
            const temp = {
                enquiry: enquiry,
                deliveryText: deliveryText,
                quoteDate: moment().format('Do MMMM YYYY'),
                itemPrices: itemPrices,
                raisedById: currentUser.currentUser.uid,
                approverId: selectedApprover,
                sections: { section6, section7, section8, section9, section10 },
                approved: false,

            }
            const db = firebase.firestore().collection("quotations");

            await db.doc(`quote${enquiry.tnNumber}`).set(temp).then(async () => {
                const newNotification: Notification = {
                    type: "Quotation approval",
                    text: "You've been assigned to approve a quotation",
                    link: `/enquiries/quotations/view/${enquiry.tnNumber}`,
                    dateTime: new Date().toDateString(),
                    viewed: false,
                }
                const notifyQuery = firebase.firestore().collection("notifications").doc(selectedApprover)
                await notifyQuery.get().then((doc) => {
                    // @ts-ignore
                    firebase.firestore().collection("notifications").doc(selectedApprover).set({ notifications: [newNotification, ...doc.data().notifications] })
                })
            }).then(() => history.push(`/enquiries/quotations/view/${enquiry.tnNumber}`))
        }
    }

    useEffect(() => {
        getEnquiry()
        getDropdowns()
    }, []);

    useEffect(() => {
        const items: { price: number; index: number; }[] = []
        const itemPricesTemp: number[] = []
        enquiry && enquiry.parts.map((value, index) => {
            items.push({ index: index, price: value.price || 0 })
            itemPricesTemp.push(value.price || 0)
        })
        setItemPrices(items)
        setSinglePrices(itemPricesTemp)
    }, [enquiry])

    useEffect(() => {
        if (selectedCurrency === "GBP") {
            setSelectedCurrencyIcon("£")
        } else if (selectedCurrency === "USD") {
            setSelectedCurrencyIcon("$")
        } else if (selectedCurrency === "EUR") {
            setSelectedCurrencyIcon("€")
        } else if (selectedCurrency === "CAD") {
            setSelectedCurrencyIcon("C$")
        }
    }, [selectedCurrency])

    let total = 0
    enquiry && enquiry.parts.map((value, index) => {
        const found = itemPrices.find((item) => item.index === index)
        let quantity = 0;
        if (typeof value.quantity === 'string') {
            quantity = parseInt(value.quantity)
        }
        if (found) {
            total += quantity * found.price
        }
    });

    const addSectionTwo = (value: string) => {
        setSection2([...section2, value])
    }

    const addSectionFive = (value: string) => {
        setSection5([...section5, section5Value])
    }

    return (
        <Screen active="Enquiries">
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <p className="mb-3">{moment().format('Do MMMM YYYY')}</p>
                <p className="font-weight-bold">{enquiry && enquiry.customer.name}</p>
                <div className="mb-3">
                    <p>{enquiry && enquiry.customer.deliveryAddressOne}</p>
                    <p>{enquiry && enquiry.customer.deliveryAddressTwo}</p>
                    <p>{enquiry && enquiry.customer.deliveryAddressThree}</p>
                    <p>{enquiry && enquiry.customer.deliveryTownCity}</p>
                    <p>{enquiry && enquiry.customer.deliveryPostCode}</p>
                </div>
                <p><span className="font-weight-bold">Your ref:</span> {enquiry && enquiry.customerRef}</p>
                <p><span className="font-weight-bold">Our ref:</span> TN{enquiry && enquiry.tnNumber}</p>
                <p className="mt-3">Dear Sirs,</p>
                <p>Further to receipt of your kind enquiry as referenced above, we now have pleasure in detailing our
                    offer as follows.</p>
                <h5 className="green-section-header mt-3">SECTION 1.0 - EQUIPMENT QUANTITY, DESCRIPTION &
                    IDENTIFICATION</h5>
                <ul>
                    {enquiry && enquiry.parts.map((value) =>
                        <li>{value.quantity} x {value.description}, {value.tagNumber}</li>)}
                </ul>
                <h5 className="green-section-header mt-3">SECTION 2.0 - SCOPE OF SUPPLY</h5>
                <div className="w-50 d-flex flex-row align-items-center">
                    {switchTwo ?
                        <Form.Control
                            onChange={(e) => setSwitchTwoVal(e.currentTarget.value)}
                            value={switchTwoVal}
                        />
                        :
                        <Form.Control onChange={(event) => {
                            setSection2Value(event.currentTarget.value)
                        }} as="select">
                            {dropdowns && dropdowns["sectionTwo"].map((val: string, index) =>
                                <option value={val}>{val}</option>
                            )}
                        </Form.Control>
                    }
                    <Button
                        onClick={() => setSwitchTwo(!switchTwo)}
                        className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                            {switchTwo ?
                        <MdFormatListBulleted size={24} color="white" />
                        :
                        <MdEdit size={24} color="white" />
                        }
                    </Button>
                    <Button
                        onClick={() => addSectionTwo(switchTwo ? switchTwoVal : section2Value)}
                        className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                        <MdAdd size={24} color="white" />
                    </Button>
                </div>
                {section2.map((val: string, index) =>
                    <p>
                        <span className="section-number">
                            {`2.${index + 1} - `}
                        </span>
                        {val}
                    </p>
                )}
                <h5 className="green-section-header mt-3">SECTION 3.0 - PRICE</h5>
                <div className="mb-2 w-25">
                    <p className="form-label">Select currency:</p>
                    <Form.Control onChange={(event) => {
                        setSelectedCurrency(event.currentTarget.value)
                    }} as="select">
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="CAD">CAD</option>
                    </Form.Control>
                </div>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Part number</th>
                            <th>Description</th>
                            <th>QTY</th>
                            <th>Unit price ({selectedCurrencyIcon})</th>
                            <th>Line price ({selectedCurrencyIcon})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enquiry && enquiry.parts.map((value, index) => {
                            const found = itemPrices.find((item) => item.index === index)
                            let quantity = 0;
                            if (typeof value.quantity === 'string') {
                                quantity = parseInt(value.quantity)
                            }
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{value.partNumber}</td>
                                    <td>{value.description}, {value.tagNumber}</td>
                                    <td className="text-right">{value.quantity}</td>
                                    <td>
                                        <Form.Control onChange={(event) => handlePriceChange({
                                            index: index,
                                            price: event.target.value
                                        })} value={found && found.price} type="Number" />
                                    </td>
                                    <td className="text-right">
                                        {selectedCurrencyIcon}{found && quantity * found.price}.00
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-right">
                                {selectedCurrencyIcon}{total}.00
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h5 className="green-section-header mt-3">SECTION 4.0 - DELIVERY</h5>
                <Form.Control as="textarea" rows={4} onChange={(event) => setDeliveryText(event.target.value)}
                    value={deliveryText} />
                <h5 className="green-section-header mt-3">SECTION 5.0 - DESIGN, MATERIALS OF CONSTRUCTION & FINISH</h5>
                <div className="w-50 d-flex flex-row align-items-center">
                {switchFive ?
                        <Form.Control
                            onChange={(e) => setSwitchFiveVal(e.currentTarget.value)}
                            value={switchFiveVal}
                        />
                        :
                        <Form.Control onChange={(event) => {
                            setSection5Value(event.currentTarget.value)
                        }} as="select">
                            {dropdowns && dropdowns["sectionFive"].map((val: string, index) =>
                                <option value={val}>{val}</option>
                            )}
                        </Form.Control>
                    }
                    <Button
                        onClick={() => setSwitchFive(!switchFive)}
                        className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                            {switchFive ?
                        <MdFormatListBulleted size={24} color="white" />
                        :
                        <MdEdit size={24} color="white" />
                        }
                    </Button>
                    <Button
                        onClick={() => addSectionFive(switchFive ? switchFiveVal : section5Value)}
                        className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                        <MdAdd size={24} color="white" />
                    </Button>
                </div>
                {section5.map((val: string, index) => <p><span
                    className="section-number">{`5.${index + 1} - `}</span>{val}</p>)}
                <div className="purchaseOrdersCreate-template-signatures">
                    <div className="d-flex flex-column">
                        <p>Quote raised by</p>
                        <h4 className="mb-0">{currentUser.currentUser.name}</h4>
                        <p className="mb-2">{currentUser.currentUser.role}</p>
                        <p>For & on behalf of Therco - Serck Ltd</p>
                        <Button
                            onClick={() => {
                                handleExtraShow()
                            }}
                            className="button-green mt-5">
                            Select extra sections
                        </Button>
                    </div>
                    <div className="d-flex flex-column">
                        <p>Quote authorised by</p>
                        <h4 className="mb-0">Not applied</h4>
                        <p className="mb-2">This Quote needs approval</p>
                        <p>For & on behalf of Therco - Serck Ltd</p>
                        <Button
                            onClick={() => {
                                getApprovers()
                                handleShow()
                            }}
                            className="button-green mt-5">
                            Send for approval
                        </Button>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select an approver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control onChange={(event) => setSelectedApprover(event.currentTarget.value)} as="select">
                        {approvers.map((value, index) => <option value={value.uid} key={index}>{value.name}</option>)}
                    </Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => handleQuoteSubmit()}
                        className="button-green w-auto">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showExtra} onHide={handleExtraClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select extra sections</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Check checked={section6} onChange={(event) => setSection6(event.target.checked)}
                        className="mb-3" inline label="Apply HSEQ, INSPECTION & TESTING" name="extra-group"
                        type="checkbox" />
                    <Form.Check checked={section7} onChange={(event) => setSection7(event.target.checked)}
                        className="mb-3" inline label="Apply PREPARATION FOR SHIPMENT" name="extra-group"
                        type="checkbox" />
                    <Form.Check checked={section8} onChange={(event) => setSection8(event.target.checked)}
                        className="mb-3" inline label="Apply GUARANTEE" name="extra-group"
                        type="checkbox" />
                    <Form.Check checked={section9} onChange={(event) => setSection9(event.target.checked)}
                        className="mb-3" inline label="Apply TERMS & CONDITIONS" name="extra-group"
                        type="checkbox" />
                    <Form.Check checked={section10} onChange={(event) => setSection10(event.target.checked)}
                        className="mb-3" inline label="Apply EXCLUSIONS" name="extra-group"
                        type="checkbox" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleExtraClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => handleExtraClose()}
                        className="button-green w-auto">
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default QuotationCreate