import React, {useEffect, useState} from 'react';
import {Button, Form, FormControl, Modal, Table} from "react-bootstrap";
import firebase from "firebase";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import {PurchaseOrder, PurchaseOrderPart} from "../Purchase Orders/PurchaseOrdersCreate";
import DatePicker from "react-datepicker";
import moment from "moment";
import toast from 'react-hot-toast';

interface CreateGRNProps {
    show: boolean
    hide: () => void
    getGrns: () => void
}

export interface GRN {
    id: number;
    dateCreated: string;
    poNumber: string;
    selectedParts: PurchaseOrderPart[];
    quantityReceived: {partId: string, quantity: number}[];
    passed: {partId: string, passed: string}[];
    dateReceived: {partId: string, date?: string}[];
    datePassed: {partId: string, date?: string}[];
    mtcNumber: {partId: string, mtcNumber: string}[];
    storedLocation?: {partId: string, location: string}[];
}

const CreateGRN = ({show = false, hide, getGrns}: CreateGRNProps) => {
    const [poNumber, setPoNumber] = useState<string>("");
    const [id, setId] = useState<number>(1);
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();
    const [selectedParts, setSelectedParts] = useState<PurchaseOrderPart[]>([]);
    const [quantityReceived, setQuantityReceived] = useState<{partId: string, quantity: number}[]>([]);
    const [passed, setPassed] = useState<{partId: string, passed: string}[]>([]);
    const [labelQty, setLabelQty] = useState<{partId: string, labelQty: number}[]>([]);
    const [dateReceived, setDateReceived] = useState<{partId: string, date: string}[]>([]);
    const [datePassed, setDatePassed] = useState<{partId: string, date: string}[]>([]);
    const [mtcNumber, setMTCNumber] = useState<{partId: string, mtcNumber: string}[]>([]);
    const [storedLocation, setStoredLocation] = useState<{partId: string, location: string}[]>([]);

    const [locations, setLocations] = useState<string[]>([])

    const searchForPurchaseOrder = async () => {
        const query = firebase.firestore().collection('purchaseOrders').doc(poNumber);
        await query.get().then((doc) => {
            // @ts-ignore
            setPurchaseOrder(doc.data())
        })
    }

    const getGrnNumber = async () => {
        const db = firebase.firestore().collection("GRN");
        const query = db.orderBy("id", "desc").limit(1).get()
        await query.then((querySnapshot) => {
            if (querySnapshot.size === 0) {
                setId(1)
            } else {
                setId(querySnapshot.docs[0].data().id + 1)
            }
        })
    }

    const getStockLocations = async () => {
        const query = firebase.firestore().collection("dropdowns").doc("stockLocation").get();
        await query.then((doc) => {
            // @ts-ignore
            setLocations(doc.data().values)
        })
    }

    const updatePartQuantity = async (parts: {partId: string, quantity: number}[]) => {
        parts.map(async (value) => {
            const query = firebase.firestore().collection("parts").doc(value.partId).get();
            await query.then((doc) => {
                // @ts-ignore
                const currentQty = doc.data().stock;
                firebase.firestore().collection("parts").doc(value.partId).update({stock: (currentQty + value.quantity)})
            })
        })
    }

    const updateStockLocation = async (obj: {location: string, partId: string}[]) => {
        obj.map(async (item) => {
            firebase.firestore().collection("parts").doc(item.partId).update({stockLocation: item.location})
        })
    }

    const saveGRN = async () => {
        const temp: GRN = {
            id,
            dateCreated: new Date().toDateString(),
            poNumber,
            selectedParts,
            quantityReceived,
            passed,
            dateReceived,
            datePassed,
            mtcNumber,
            storedLocation
        }
        console.log(temp)
        const db = firebase.firestore().collection("GRN").doc(id.toString());
        if (temp) {
            await db.set(temp).then(() => updatePartQuantity(quantityReceived))
        }
        if (storedLocation.length > 0) {
            updateStockLocation(storedLocation)
        }
        toast.success("GRN created!")
        hide();
        setPoNumber("")
        setSelectedParts([])
        setQuantityReceived([])
        setPassed([])
        setLabelQty([])
        setDateReceived([])
        setDatePassed([])
        setMTCNumber([])
        setStoredLocation([])
        getGrns()
    };

    useEffect(() => {
        getGrnNumber()
        getStockLocations()
    }, [])


    console.log(dateReceived)
    return (
        <Modal dialogClassName="extra-large-dialog" show={show} onHide={() => hide()}>
            <Modal.Header closeButton>
                <Modal.Title>{`Create goods received note`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <p className="form-label">PO Number:</p>
                    <div className="d-flex flex-row">
                        <Form.Control className="w-25" value={poNumber}
                                      onChange={(event) => setPoNumber(event.target.value)}/>
                        <Button
                            onClick={() => searchForPurchaseOrder()}
                            className="button-green button-green--search d-flex align-items-center justify-content-center w-auto ml-2">
                            <img src={searchIcon}/>
                        </Button>
                    </div>
                </div>
                {purchaseOrder &&
                <div className="mb-4">
                    <p className="form-label">Select received parts:</p>
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Part ID</th>
                            <th>Description</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {purchaseOrder.parts.map((part, index) => {
                            if (!selectedParts.includes(part)) {
                                return (
                                    <tr onClick={() => setSelectedParts([...selectedParts, part])}>
                                        <td>{index + 1}</td>
                                        <td>{part.part.id}</td>
                                        <td>{part.part.description}, {part.part.dimensions}, {part.part.material}</td>
                                        <td>{part.quantity}</td>
                                    </tr>
                                )
                            }
                        })}
                        </tbody>
                    </Table>
                </div>
                }
                {selectedParts.length > 0 &&
                <div className="mb-4">
                    <p className="form-label">Selected parts:</p>
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th>Part ID</th>
                            <th>Desc, Dimension, Material</th>
                            <th>QTY</th>
                            <th>Date received</th>
                            <th>Passed?</th>
                            <th>Passed date</th>
                            <th>MTC No.</th>
                            <th>Stock location</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedParts.map((part, index) => {
                            let quantity = 0
                            if (quantityReceived.filter(value => value.partId === part.part.id).length > 0) {
                                quantity = quantityReceived.filter(value => value.partId === part.part.id)[0].quantity
                            }

                            let date = ""
                            if (dateReceived.filter(value => value.partId === part.part.id).length > 0) {
                                date = dateReceived.filter(value => value.partId === part.part.id)[0].date
                            }

                            let mtc = ""
                            if (mtcNumber.filter(value => value.partId === part.part.id).length > 0) {
                                mtc = mtcNumber.filter(value => value.partId === part.part.id)[0].mtcNumber
                            }

                            let pass = ""
                            if (passed.filter(value => value.partId === part.part.id).length > 0) {
                                pass = passed.filter(value => value.partId === part.part.id)[0].passed
                            }

                            let datePass = ""
                            if (datePassed.filter(value => value.partId === part.part.id).length > 0) {
                                datePass = datePassed.filter(value => value.partId === part.part.id)[0].date
                            }

                            let labels = 0
                            if (labelQty.filter(value => value.partId === part.part.id).length > 0) {
                                labels = labelQty.filter(value => value.partId === part.part.id)[0].labelQty
                            }

                            return (
                                <tr>
                                    <td>{part.part.id}</td>
                                    <td>{part.part.description && `${part.part.description}`}{part.part.dimensions && `, ${part.part.dimensions}, `}{part.part.material}</td>
                                    <td>
                                        <FormControl
                                            onChange={(event) => {
                                                const temp = quantityReceived.filter((value) => value.partId !== part.part.id)
                                                temp.push({partId: part.part.id, quantity: parseInt(event.currentTarget.value)})
                                                setQuantityReceived(temp)
                                            }}
                                            value={quantity}
                                            type="Number"
                                            placeholder="Quantity received..."
                                        />
                                    </td>
                                    <td>
                                        <DatePicker
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="Date received..." className="form-control"
                                            wrapperClassName="w-100"
                                            value={moment(new Date(date)).format("DD/MM/YYYY")}
                                            onChange={(dateChange) => {
                                                if (dateChange instanceof Date) {
                                                    const temp = dateReceived.filter((value) => value.partId !== part.part.id)
                                                    temp.push({partId: part.part.id, date: dateChange.toDateString()})
                                                    setDateReceived(temp)
                                                }
                                            }}/>
                                    </td>
                                    <td>
                                        <Form.Control value={pass} as="select" onChange={(event) => {
                                            const temp = passed.filter((value) => value.partId !== part.part.id)
                                            temp.push({partId: part.part.id, passed: event.currentTarget.value})
                                            setPassed(temp)
                                        }}>
                                            <option value="no">No</option>
                                            <option value="yes">Yes</option>
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <DatePicker
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="Date passed..." className="form-control"
                                            wrapperClassName="w-100"
                                            value={moment(new Date(datePass)).format("DD/MM/YYYY")}
                                            onChange={(date) => {
                                                if (date instanceof Date) {
                                                    const temp = datePassed.filter((value) => value.partId !== part.part.id)
                                                    temp.push({partId: part.part.id, date: date.toDateString()})
                                                    setDatePassed(temp)
                                                }
                                            }}/>
                                    </td>
                                    <td>
                                        <FormControl
                                            onChange={(event) => {
                                                const temp = mtcNumber.filter((value) => value.partId !== part.part.id)
                                                temp.push({partId: part.part.id, mtcNumber: event.currentTarget.value})
                                                setMTCNumber(temp)
                                            }}
                                            value={mtc}
                                            placeholder="MTC number..."
                                        />
                                    </td>
                                    <td>
                                        <Form.Control as="select"
                                        onChange={(event) => {
                                            const temp = storedLocation.filter((value) => value.partId !== part.part.id)
                                            temp.push({partId: part.part.id, location: event.currentTarget.value})
                                            setStoredLocation(temp)
                                        }}
                                        >
                                            <option value={""}>Select...</option>
                                            {locations.map((value: string) => {
                                                return (
                                                    <option value={value}>{value}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={selectedParts.length < 1} onClick={() => saveGRN()} className="button-green w-auto">
                    Save GRN
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateGRN