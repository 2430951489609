import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import { useParams, useHistory } from 'react-router-dom';
import moment from "moment";
import { toast } from "react-hot-toast";
import { Form, Table, Button } from "react-bootstrap";
import { SalesOrder } from "./OrderProcessing";
import DatePicker from "react-datepicker";

export interface OrderAcknowledgement {
    id: string;
    salesOrder: SalesOrder;
    scope: string;
    warranty: string;
    deliveryDate: string;
    delivery: number;
    totalPrice: number;
    dateRaised: string;
}

const OrderAcknowledgement = () => {
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [scope, setScope] = useState<string>("The items described in section 1.0 shall be manufactured to proprietary standards and specifications. Unless agreed otherwise, preparation for shipment shall include light oiling of all self-colour surfaces, light ingress protection of all open nozzles and (where relevant) shrink-wrapping before securing to a pallet for domestic deliveries, or into a timber crate for export deliveries.")
    const [warranty, setWarranty] = useState<string>("THERCO-SERCK warrants that (subject to the other provisions of its terms and conditions of sale, see section 5.0) upon delivery, and for a period of 18 months from the date of delivery, or 12 months from the date of installation (whichever expires first) the goods described above shall be of satisfactory quality within the meaning of the sale of goods act 1979.")
    const [deliveryDate, setDeliveryDate] = useState(moment().format("DD/MM/YYYY"))
    const [delivery, setDelivery] = useState<number>(0)
    const history = useHistory();
    // @ts-ignore
    let { id } = useParams();

    const getSalesOrder = async () => {
        const query = firebase.firestore().collection('salesOrders').doc(`SO${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setSalesOrder(doc.data())
        })
    }

    useEffect(() => {
        getSalesOrder()
    }, [])

    let total = 0

    if (salesOrder) {
        salesOrder.parts.map((value, index) =>
            total = total + (salesOrder.quote.itemPrices[index].price * value.quantity) + delivery
        );
    }
    
    const saveOrderAcknowledgement = async () => {
        // @ts-ignore
        const temp: OrderAcknowledgement = { id, salesOrder, scope, warranty, deliveryDate, delivery, totalPrice: total, dateRaised: moment().format('DD/MM/YYYY')};
            const db = firebase.firestore().collection("salesOrders").doc(`SO${id}`);
            if (temp) {
                await db.update({OA: temp}).then(() => {
                    toast.success(`Order acknowledgement saved successfully`)
                    history.push(`/order-processing/order-acknowledgement/view/${id}`)
                })
            }
    }

    return (
        <Screen active="Order acknowledgement">
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <h3 className="green-section-header mt-3">ORDER ACKNOWLEDGEMENT</h3>
                        <p className="green-section-header font-weight-bold mt-1">CLIENT PO: <span
                            className="font-weight-normal black-text">{salesOrder && salesOrder.customerPo}</span></p>
                        <p className="green-section-header font-weight-bold mt-1">OUR REF: <span
                            className="font-weight-normal black-text">TN{salesOrder && salesOrder.quote.enquiry.tnNumber} / SO{salesOrder && salesOrder.quote.enquiry.tnNumber}</span>
                        </p>
                        <p className="green-section-header font-weight-bold mt-1">DATE: <span
                            className="font-weight-normal black-text">{moment().format('Do MMMM YYYY')}</span></p>
                    </div>
                    <div>
                        <p className="font-weight-bold">DELIVERY ADDRESS</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.townCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.postCode}</p>
                    </div>
                    <div>
                        <p className="font-weight-bold">INVOICE ADDRESS</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressOne}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressTwo}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressThree}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceTownCity}</p>
                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoicePostCode}</p>
                    </div>
                </div>
                <h5 className="green-section-header mt-5">SECTION 1.0 - QUANTITY, DESCRIPTION & PRICE</h5>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th className="text-right tbl-qty-w1">QTY</th>
                            <th className='tbl-qty-w4'>Part number</th>
                            <th>Description</th>
                            <th className="text-right tbl-qty-w3">Unit price</th>
                            <th className="text-right">Line price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrder && salesOrder.parts.map((value, index) => {


                            return (
                                <tr>
                                    <td className="text-right">{value.quantity}</td>
                                    <td>{value.partNumber}</td>
                                    <td>{value.description}</td>
                                    <td className="text-right">£{salesOrder.quote.itemPrices[index].price}</td>
                                    <td className="text-right">£{salesOrder.quote.itemPrices[index].price * value.quantity}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Delivery</td>
                            <td className='small-qty'>
                            <Form.Control onChange={(event) => setDelivery(parseInt(event.currentTarget.value))} value={delivery} type="Number"/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='font-weight-bold text-right'>Total</td>
                            <td className="text-right">
                                £{total}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h5 className="green-section-header mt-3">SECTION 2.0 - SCOPE OF SUPPLY</h5>
                <Form.Control as="textarea" value={scope} onChange={(val) => setScope(val.currentTarget.value)} rows={4} />
                <h5 className="green-section-header mt-3">SECTION 3.0 - DELIVERY</h5>
                <p>Based on material availability and shop loadings at the time of acknowledging your order, we estimate that the equipment will be delivered on</p>
                <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    placeholderText="Enter delivery date..." className="form-control"
                    wrapperClassName="w-25"
                    value={moment(deliveryDate).format("DD/MM/YYYY")}
                    onChange={(date) => {
                        if (date instanceof Date) {
                            setDeliveryDate(date.toDateString())
                        }
                    }} />
                <h5 className="green-section-header mt-3">SECTION 4.0 - WARRANTY</h5>
                <Form.Control as="textarea" rows={4} value={warranty} onChange={(val) => setWarranty(val.currentTarget.value)} />
                <h5 className="green-section-header mt-5">SECTION 5.0 - TERMS, CONDITIONS & PAYMENT</h5>
                <p>THERCO-SERCK Terms and Conditions of Sale (available on request) apply to this order, for payment, please use the following account;
                    Lloyds Bank plc, 14 Church Street, Sheffield, S1 1HP United Kingdom
                </p>
                <p>Account name: Therco – Serck Ltd | Account number 66135968 | Sort Code 30-97-51 | IBAN GB19LOYD30975166135968 | BIC LOYDGB21040</p>
                <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => saveOrderAcknowledgement()}
                        className="button-green mt-5 w-auto">
                        Create order acknowledgement
                    </Button>
                </div>
            </div>
        </Screen>
    )
}

export default OrderAcknowledgement