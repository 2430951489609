import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import { Accordion, Button, Card, Col, Form, FormControl, Row, Table } from "react-bootstrap";
import { MdAdd, MdAssignment, MdNoteAdd, MdRemove } from "react-icons/md";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import SalesOrderCreate from "./SalesOrderCreate";
import firebase from "firebase";
import { Quote } from "../Enquiry processing/QuotationView";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import OrderProcessingModal from "./OrderProcessingModal";
import { OrderAcknowledgement } from './OrderAcknowledgement';
import { DeliveryNote } from '../Delivery Note/DeliveryNote';
import { SalesInvoice } from './SalesInvoice';
import { Enquiry } from '../Enquiry processing/Enquiries';

export interface SalesOrder {
    parts: { quantity: number, partNumber: string, description: string, tagNumber: string, date?: string, nominalCode?: string }[],
    quote: Quote,
    customerPo: string,
    poRecievedDate: string
    deliveryDates?: {partId: string, date: string}[],
    closed?: boolean
    closedDate?: string
    notes?: { text: string, username: string, date: string }[]
    notesLd?: { text: string, username: string, date: string }[]
    OA?: OrderAcknowledgement
    DN?: DeliveryNote
    SI?: SalesInvoice
}

const OrderProcessing = () => {
    const [showCreate, setShowCreate] = useState<boolean>(false)
    const [showPO, setShowPO] = useState<boolean>(false)
    const [poParts, setPoParts] = useState<any[]>([])
    const [searchType, setSearchType] = useState<string>("SO number")
    const [searchText, setSearchText] = useState<string>("")
    const [selectedTN, setSelectedTn] = useState<Enquiry>()
    const [data, setData] = useState<any>([])
    const [acitveKey, setActiveKey] = useState<number>()
    const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([])

    const getSalesOrders = async () => {
            let temp: SalesOrder[] = []
            const query = firebase.firestore().collection('salesOrders')
                .limit(30)

            const enquiriesRequest = await query.get()
            // @ts-ignore
            enquiriesRequest.docs.map((value) => temp.push(value.data()))
            setData(enquiriesRequest)
            setSalesOrders([...temp])
    }

    const closeJob = async (docId: string) => {
        const query = firebase.firestore().collection('salesOrders').doc(docId)
        query.update({ closed: true, closedDate: new Date().toDateString() })
        getSalesOrders()
    }

    const getMoreSalesOrders = async () => {
        if (salesOrders) {
            let temp: SalesOrder[] = []
            const lastVisible = data && data.docs[data.docs.length - 1]
            const query = firebase.firestore().collection('salesOrders')
                .startAfter(lastVisible)
                .limit(30)

            const enquiriesRequest = await query.get()
            // @ts-ignore
            enquiriesRequest.docs.map((value) => temp.push(value.data()))
            setData(enquiriesRequest)
            setSalesOrders([...salesOrders, ...temp])
        }
    }

    const mobileQuery = window.matchMedia('(max-width: 850px)')


    useEffect(() => {
        getSalesOrders()
    }, [])

    useEffect(() => {
        if (poParts.length > 0) {
            console.log(poParts)
            setShowPO(true)
        }
    }, [poParts])

    return (
        <Screen active="Order processing">
            <SalesOrderCreate submit={() => {
                getSalesOrders()
            }} show={showCreate} hide={() => setShowCreate(false)} />
            <OrderProcessingModal tn={selectedTN} parts={poParts} show={showPO} hide={() => setShowPO(false)} />
            <Card>
                <Card.Header
                    className="card-header--sticky purchaseOrders-header d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <MdAssignment style={{ marginRight: ".3rem" }} size={18} color="#131313" />
                        <p className="font-weight-bold">Order processing</p>
                    </div>
                    {!mobileQuery.matches &&
                        <div className="d-flex flex-row align-items-center">
                            <Form.Control onChange={(event) => setSearchType(event.currentTarget.value)}
                                style={{ width: "10rem", marginLeft: "-10.5rem" }} as="select">
                                <option value="SO number">SO number</option>
                                <option value="customer name">Customer name</option>
                            </Form.Control>
                            <FormControl
                                onKeyDown={(e: any) => e.key === "Enter"}
                                onChange={(event) => setSearchText(event.currentTarget.value)}
                                value={searchText}
                                style={{ marginLeft: ".3rem", marginRight: ".3rem" }}
                                className="datasheet-list-head__search"
                                placeholder={`Search sales orders by ${searchType}...`}
                            />
                            <Button
                                onClick={() => {
                                }}
                                className="button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                <img src={searchIcon} />
                            </Button>
                        </div>
                    }
                    <div className="d-flex flex-row align-items-center purchaseOrders-header-controls">
                        <a onClick={() => setShowCreate(true)}>
                            <MdNoteAdd size={20} color="#131313" />
                            {` New sales order`}
                        </a>
                    </div>
                </Card.Header>
                <Card.Body className="purchaseOrders-body">
                    <InfiniteScroll
                        dataLength={salesOrders.length}
                        next={() => getMoreSalesOrders()}
                        hasMore={true}
                        scrollableTarget="screen-container"
                        loader=""
                    >
                        <Accordion>
                            {salesOrders.sort((a, b) => parseInt(b.quote.enquiry.tnNumber)-parseInt(a.quote.enquiry.tnNumber)).map((salesOrder, index) => {
                                console.log(salesOrder.closed)
                                return (
                                    <Card>
                                        <Accordion.Toggle
                                            onClick={() => {
                                                if (acitveKey === index) {
                                                    setActiveKey(undefined)
                                                } else {
                                                    setActiveKey(index)
                                                }
                                            }}
                                            className="purchaseOrders-body-accordion-header"
                                            as={Card.Header} eventKey={index.toString()}>
                                            {`SO${salesOrder.quote.enquiry.tnNumber} - Customer: ${salesOrder.quote.enquiry.customer.name} ${salesOrder.closed ? " - Job complete" : " - Ongoing job"}`}
                                            {acitveKey === index ? <MdRemove size={20} /> : <MdAdd size={20} />}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <div className="d-flex flex-column mb-2">
                                                        <p className="font-weight-bold">TN Number:</p>
                                                        <p className="mb-1">TN{salesOrder.quote.enquiry.tnNumber}</p>
                                                            <p className="font-weight-bold">Customer PO:</p>
                                                            <p className="mb-1">{salesOrder.customerPo}</p>
                                                            <p className="font-weight-bold">PO received date:</p>
                                                            <p className="mb-1">{salesOrder.poRecievedDate}</p>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="d-flex flex-column mb-2">
                                                            <p className="font-weight-bold">Stage payment profile:</p>
                                                            {salesOrder.notes && salesOrder.notes.map((value) => {
                                                                return (
                                                                    <div className="mb-3">
                                                                        <p>{value.text}</p>
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <p className="font-weight-bold">{`- ${value.username} ${value.date}`}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="d-flex flex-column mb-2">
                                                            <p className="font-weight-bold">L.D.’s, bonds and guarantees:</p>
                                                            {salesOrder.notesLd && salesOrder.notesLd.map((value) => {
                                                                return (
                                                                    <div className="mb-3">
                                                                        <p>{value.text}</p>
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <p className="font-weight-bold">{`- ${value.username} ${value.date}`}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Table bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Part Number</th>
                                                            <th>Description</th>
                                                            <th>Quantity</th>
                                                            <th>Tag Number</th>
                                                            <th>Delivery Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {salesOrder.parts.map((value: any, index: any, array: any) => {
                                                            const delDate = salesOrder.deliveryDates?.filter((a) => a.partId === value.partNumber)
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{value.partNumber}</td>
                                                                    <td>{value.description}</td>
                                                                    <td className="text-right">{value.quantity}</td>
                                                                    <td>{value.tagNumber}</td>
                                                                    <td>{delDate ? delDate[0].date : ""}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>

                                                <Row className="mt-3">
                                                    <Col>
                                                        <div className={`d-flex w-100 justify-content-end ${mobileQuery.matches && "flex-column"}`}>
                                                            <Link
                                                                to={salesOrder.OA ? `/order-processing/order-acknowledgement/view/${salesOrder.quote.enquiry.tnNumber}` : `/order-processing/order-acknowledgement/${salesOrder.quote.enquiry.tnNumber}`}
                                                                className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                {salesOrder.OA ? "View order acknowledgement" : "Create order acknowledgement"}
                                                            </Link>
                                                            {!salesOrder.closed &&
                                                                <Button
                                                                    onClick={() => {
                                                                        setPoParts(salesOrder.parts)
                                                                        setSelectedTn(salesOrder.quote.enquiry)
                                                                    }
                                                                    }
                                                                    className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                    Create purchase order
                                                                </Button>
                                                            }
                                                            <Link
                                                                to={salesOrder.SI ? `/order-processing/sales-invoice/view/${salesOrder.quote.enquiry.tnNumber}` : `/order-processing/sales-invoice/${salesOrder.quote.enquiry.tnNumber}`}
                                                                className="button-green w-auto ml-2">
                                                                {salesOrder.SI ? "View sales invoice" : "Create sales invoice"}
                                                            </Link>
                                                            <Link
                                                                to={{
                                                                    pathname: salesOrder.DN ? `/order-processing/delivery-note/view/${salesOrder.quote.enquiry.tnNumber}` : `/order-processing/delivery-note/${salesOrder.quote.enquiry.tnNumber}`,
                                                                    state: {
                                                                        state: {
                                                                            so: salesOrder,
                                                                            quote: salesOrder.quote
                                                                        }
                                                                    }
                                                                }}
                                                                className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                {salesOrder.DN ? "View delivery note" : "Create delivery note"}
                                                            </Link>
                                                            {!salesOrder.closed &&
                                                                <a
                                                                    onClick={() => closeJob(`SO${salesOrder.quote.enquiry.tnNumber}`)}
                                                                    className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                    Close job
                                                                </a>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                    </InfiniteScroll>
                </Card.Body>
            </Card>
        </Screen>
    )
}

export default OrderProcessing