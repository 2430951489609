import React, {useContext, useEffect, useState} from 'react';
import Screen from "../../Components/Screen";
import logo from '../../Images/logo-dark.png'
import moment from "moment";
import firebase from "firebase";
import {useParams} from "react-router";
import {Enquiry} from "./Enquiries";
import {Button, Modal, Table} from "react-bootstrap";
import {globalUser, Notification, User} from "../../App";
import {toast} from "react-hot-toast";
import BackpageSections from "../../Components/BackpageSections";

export interface Quote {
    enquiry: Enquiry
    deliveryText: string
    quoteDate: string
    itemPrices: { price: number, index: number }[]
    raisedById: string
    approverId: string
    approved: boolean
    sections: { section6: boolean, section7: boolean, section8: boolean, section9: boolean, section10: boolean }
}

const QuotationView = () => {
    const [quote, setQuote] = useState<Quote>()
    const [approver, setApprover] = useState<User>()
    const [creator, setCreator] = useState<User>()
    const [dropdowns, setDropdowns] = useState({
        sectionTwo: [],
        sectionFive: [],
        sectionSix: [],
        sectionSeven: [],
        sectionEight: [],
        sectionNine: [],
        sectionTen: []
    })
    const [printing, setPrinting] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const currentUser = useContext(globalUser)


    const handlePrint = () => {
        setPrinting(true)
    }


    // @ts-ignore
    let {id} = useParams();
    const getQuote = async () => {
        const query = firebase.firestore().collection('quotations').doc(`quote${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setQuote(doc.data())
        })
    };

    const getDropdowns = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("sections");
        await db.get().then((snap) => {
            if (snap.exists) {
                // @ts-ignore
                setDropdowns(snap.data().values)
            }
        })
    }

    const getApprover = async () => {
        if (quote) {
            const db = firebase.firestore().collection("users").doc(quote.approverId);
            await db.get().then((snap) => {
                if (snap.exists) {
                    // @ts-ignore
                    setApprover(snap.data())
                }
            })
        }
    }

    const approveQuote = async () => {
        if (quote) {
            let newQuote: Quote = {
                ...quote,
                approved: true
            }
            const db = firebase.firestore().collection("quotations");
            await db.doc(`quote${quote.enquiry.tnNumber}`).set(newQuote).then(() => {
                getQuote()
                handleClose()
                toast.success(`Quote approved!`)
            }).then(async () => {
                const newNotification: Notification = {
                    type: "Quotation approved!",
                    text: "Your quote has been approved",
                    link: `/enquiries/quotations/view/${quote.enquiry.tnNumber}`,
                    dateTime: new Date().toDateString(),
                    viewed: false,
                }
                const notifyQuery = firebase.firestore().collection("notifications").doc(quote.raisedById)
                await notifyQuery.get().then((doc) => {
                    if (doc.exists) {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(quote.raisedById).set({notifications: [newNotification, ...doc.data().notifications]})
                    } else {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(quote.raisedById).set({notifications: [newNotification]})
                    }
                })
            })
        }
    }

    const getCreator = async () => {
        if (quote) {
            const db = firebase.firestore().collection("users").doc(quote.raisedById);
            await db.get().then((snap) => {
                if (snap.exists) {
                    // @ts-ignore
                    setCreator(snap.data())
                }
            })
        }
    }


    useEffect(() => {
        getDropdowns()
        getQuote()
    }, [])

    useEffect(() => {
        getApprover()
        getCreator()
    }, [quote])

    console.log(quote)
    let total = 0
    if (quote) {
        quote.enquiry.parts.map((value, index) => {
            const found = quote.itemPrices.find((item) => item.index === index)
            let quantity = 0;
            if (typeof value.quantity === 'string') {
                quantity = parseInt(value.quantity)
            }

            if (found) {
                total += quantity * found.price
            }
        })
    }

    if (printing && quote) {
        return (
            <div className="bg-white p-5">
                <img className="mb-5" src={logo}/>
                <p className="mb-3">{moment().format('Do MMMM YYYY')}</p>
                <p className="font-weight-bold">{quote.enquiry.customer.name}</p>
                <div className="mb-3">
                    <p>{quote.enquiry.customer.deliveryAddressOne}</p>
                    <p>{quote.enquiry.customer.deliveryAddressTwo}</p>
                    <p>{quote.enquiry.customer.deliveryAddressThree}</p>
                    <p>{quote.enquiry.customer.deliveryTownCity}</p>
                    <p>{quote.enquiry.customer.deliveryPostCode}</p>
                </div>
                <p><span className="font-weight-bold">Your ref:</span>{quote.enquiry.customerRef}</p>
                <p><span className="font-weight-bold">Our ref:</span> TN{quote.enquiry.tnNumber}</p>
                <p className="mt-3">Dear Sirs,</p>
                <p>Further to receipt of your kind enquiry as referenced above, we now have pleasure in detailing
                    our
                    offer as follows.</p>
                <h5 className="green-section-header mt-3">SECTION 1.0 - EQUIPMENT QUANTITY, DESCRIPTION &
                    IDENTIFICATION</h5>
                <ul>
                    {quote.enquiry.parts.map((value) =>
                        <li>{value.quantity} x {value.description} {value.tagNumber}</li>)}
                </ul>
                <h5 className="green-section-header mt-3">SECTION 2.0 - SCOPE OF SUPPLY</h5>
                {dropdowns && dropdowns["sectionTwo"].map((val: string, index) =>
                    <p>
                        <span className="section-number">
                            {`2.${index + 1} - `}
                        </span>
                        {val}
                    </p>
                )}
                <h5 className="green-section-header mt-3">SECTION 3.0 - PRICE</h5>
                <Table bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Part number</th>
                        <th>Description / Tag number</th>
                        <th>QTY</th>
                        <th>Unit price (£)</th>
                        <th>Line price (£)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {quote.enquiry.parts.map((value, index) => {
                        const found = quote.itemPrices.find((item) => item.index === index)
                        let quantity = 0;
                        if (typeof value.quantity === 'string') {
                            quantity = parseInt(value.quantity)
                        }

                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{value.partNumber}</td>
                                <td>{value.description}, {value.tagNumber}</td>
                                <td>{value.quantity}</td>
                                <td>
                                    {found && found.price}.00
                                </td>
                                <td>
                                    £{found && quantity * found.price}.00
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <h5 className="green-section-header mt-3">SECTION 4.0 - DELIVERY</h5>
                <p>{quote && quote.deliveryText}</p>
                <h5 className="green-section-header mt-3">SECTION 5.0 - DESIGN, MATERIALS OF CONSTRUCTION &
                    FINISH</h5>
                {dropdowns && dropdowns["sectionFive"].map((val: string, index) => <p><span
                    className="section-number">{`5.${index + 1} - `}</span>{val}</p>)}
                <div className="purchaseOrdersCreate-template-signatures mt-lg-5">
                    <div className="d-flex flex-column">
                        <p>Quote raised by</p>
                        <h4 className="mb-0">{creator && creator.name}</h4>
                        <p className="mb-2">{creator && creator.role}</p>
                        <p>For & on behalf of Therco - Serck Ltd</p>
                    </div>
                    {quote && quote.approved ?
                        <div className="d-flex flex-column">
                            <p>Quote authorised by</p>
                            <h4 className="mb-0">{approver && approver.name}</h4>
                            <p className="mb-2">{approver && approver.role}</p>
                            <p>For & on behalf of Therco - Serck Ltd</p>
                        </div>
                        :
                        <>
                            <div className="d-flex flex-column">
                                <p>Quote to be authorised by</p>
                                <h4 className="mb-0">{approver && approver.name}</h4>
                                <p className="mb-2">This quote is not authorised</p>
                                <p>For & on behalf of Therco - Serck Ltd</p>
                            </div>
                        </>
                    }
                </div>
                <div className="d-flex flex-row justify-content-end w-100 mt-5 PO-printing">
                    <div className="d-flex flex-row align-items-center">
                        <Button
                            onClick={() => setPrinting(false)}
                            className="button-green ml-3">
                            Cancel preview
                        </Button>
                        <Button
                            onClick={() => window.print()}
                            className="button-green ml-3">
                            Download PDF
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {quote &&
            <Screen active="Enquiries">
                <div className="bg-white p-5">
                    <img className="mb-5" src={logo}/>
                    <p className="mb-3">{moment().format('Do MMMM YYYY')}</p>
                    <p className="font-weight-bold">{quote.enquiry.customer.name}</p>
                    <div className="mb-3">
                        <p>{quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{quote.enquiry.customer.deliveryTownCity}</p>
                        <p>{quote.enquiry.customer.deliveryPostCode}</p>
                    </div>
                    <p><span className="font-weight-bold">Your ref:</span> {quote.enquiry.customerRef}</p>
                    <p><span className="font-weight-bold">Our ref:</span> TN{quote.enquiry.tnNumber}</p>
                    <p className="mt-3">Dear Sirs,</p>
                    <p>Further to receipt of your kind enquiry as referenced above, we now have pleasure in detailing
                        our
                        offer as follows.</p>
                    <h5 className="green-section-header mt-3">SECTION 1.0 - EQUIPMENT QUANTITY, DESCRIPTION &
                        IDENTIFICATION</h5>
                    <ul>
                        {quote.enquiry.parts.map((value) => <li>{value.quantity} x {value.description}</li>)}
                    </ul>
                    <h5 className="green-section-header mt-3">SECTION 2.0 - SCOPE OF SUPPLY</h5>
                    {dropdowns && dropdowns["sectionTwo"].map((val: string, index) =>
                        <p>
                        <span className="section-number">
                            {`2.${index + 1} - `}
                        </span>
                            {val}
                        </p>
                    )}
                    <h5 className="green-section-header mt-3">SECTION 3.0 - PRICE</h5>
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Part number</th>
                            <th className="w-75">Description</th>
                            <th>QTY</th>
                            <th>Unit price (£)</th>
                            <th>Line price (£)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {quote.enquiry.parts.map((value, index) => {
                            const found = quote.itemPrices.find((item) => item.index === index)
                            let quantity = 0;
                            if (typeof value.quantity === 'string') {
                                quantity = parseInt(value.quantity)
                            }

                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{value.partNumber}</td>
                                    <td>{value.description}, {value.tagNumber}</td>
                                    <td className="text-right">{value.quantity}</td>
                                    <td className="text-right">
                                        £{found && found.price}.00
                                    </td>
                                    <td className="text-right">
                                        £{found && quantity * found.price}.00
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-right">
                                £{total}.00
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <h5 className="green-section-header mt-3">SECTION 4.0 - DELIVERY</h5>
                    <p>{quote && quote.deliveryText}</p>
                    <h5 className="green-section-header mt-3">SECTION 5.0 - DESIGN, MATERIALS OF CONSTRUCTION &
                        FINISH</h5>
                    {dropdowns && dropdowns["sectionFive"].map((val: string, index) => <p><span
                        className="section-number">{`5.${index + 1} - `}</span>{val}</p>)}

                    <div className="purchaseOrdersCreate-template-signatures">
                        <div className="d-flex flex-column">
                            <p>Quote raised by</p>
                            <h4 className="mb-0">{creator && creator.name}</h4>
                            <p className="mb-2">{creator && creator.role}</p>
                            <p>For & on behalf of Therco - Serck Ltd</p>
                        </div>
                        {quote && quote.approved ?
                            <div className="d-flex flex-column">
                                <p>Quote authorised by</p>
                                <h4 className="mb-0">{approver && approver.name}</h4>
                                <p className="mb-2">{approver && approver.role}</p>
                                <p>For & on behalf of Therco - Serck Ltd</p>
                            </div>
                            :
                            <>
                                <div className="d-flex flex-column">
                                    <p>Quote to be authorised by</p>
                                    <h4 className="mb-0">{approver && approver.name}</h4>
                                    <p className="mb-2">This quote is not authorised</p>
                                    <p>For & on behalf of Therco - Serck Ltd</p>
                                </div>
                            </>
                        }
                    </div>
                    {quote && !quote.approved ?
                        <div className="d-flex flex-row align-items-center justify-content-end mt-5">
                            <Button
                                onClick={() => {
                                }}
                                className="button-green width-fit ml-3">
                                Edit quote
                            </Button>
                            <Button
                                onClick={() => {
                                }}
                                className="button-green width-fit ml-3">
                                Delete quote
                            </Button>
                            <Button
                                onClick={() => handlePrint()}
                                className="button-green ml-3 width-fit">
                                View preview & print
                            </Button>
                            {quote &&
                            (currentUser.currentUser.uid === quote.approverId &&
                                <Button
                                    onClick={() => handleShow()}
                                    className="button-green width-fit ml-3">
                                    Approve this quote
                                </Button>
                            )
                            }
                        </div>
                        :
                        <></>
                    }
                    {quote && quote.approved &&
                    <div className="d-flex flex-row align-items-center justify-content-end mt-5">
                    <Button
                        onClick={() => handlePrint()}
                        className="button-green ml-3 width-fit">
                        View preview & print
                    </Button>
                </div>
                    }
                    <BackpageSections sections={quote.sections} lastSectionNumber={5}/>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Please confirm approval</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                approveQuote()
                            }}
                            className="button-green w-auto">
                            Approve
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Screen>
            }
        </>
    )
}

export default QuotationView