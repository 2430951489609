import React, {useEffect, useState} from 'react';
import Screen from "../../../Components/Screen";
import firebase from "firebase";
import {GRN} from "../../Goods in/CreateGRN";
import {useLocation} from "react-router";
import logo from "../../../Images/logo-dark.png";
import {Button, Card, Table} from "react-bootstrap";

const TotalStockBySo = () => {
    const [grn, setGrn] = useState()
    const location = useLocation()
    const getGrns = async () => {
        // @ts-ignore
        const id = `SO${location.state?.so}`
        const query = firebase.firestore().collection('salesOrders').doc(id)
        await query.get().then((doc) => {
            // @ts-ignore
            setGrn(doc.data())
        })
    }

    useEffect(() => {
        getGrns()
    }, [])
    // @ts-ignore
    const title = `Total stock list for SO${location.state?.so}`

    console.log(grn)

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo}/>
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text text-uppercase">{title}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – GRN LIST</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className="text-right">#</th>
                                    <th>Part number</th>
                                    <th>Description</th>
                                    <th className="text-right">Stock QTY</th>
                                    <th>Line cost</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            <Button
                                className="button-green ml-3">
                                View preview & print
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default TotalStockBySo