import React, { useEffect, useState } from 'react';
import logo from "../../Images/logo-dark.png";
import Screen from "../../Components/Screen";
import { useHistory } from "react-router";
import { Table, Form, Button } from "react-bootstrap";
import { SalesOrder } from '../Order processing/OrderProcessing';
import { Quote } from '../Enquiry processing/QuotationView';

const DeliveryNoteView = () => {
    const [preview, setPreview] = useState<boolean>(false)
    const history = useHistory();
    console.log(history.location)
    // @ts-ignore
    const passedState: { so: SalesOrder, quote: Quote } = history.location.state.state;
    console.log(passedState)


    if (preview) {
        return (
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`DELIVERY NOTE`}</h2>
                    <div className="d-flex flex-column mt-4 mb-5">
                        <h5 className="mb-0 purchaseOrdersCreate-template__green-text">Delivery address</h5>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryTownCity}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryPostCode}</p>
                    </div>
                    <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Therco-Serck reference</h5>
                                <p>TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Therco-Serck contact</h5>
                                <p>{passedState.so.DN?.contact}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Customer PO</h5>
                                <p>{passedState.so.customerPo}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Order number</h5>
                                <p>{passedState.so.DN?.orderNumber}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Commodity code</h5>
                                <p>{passedState.so.DN?.commodity}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Invoice number</h5>
                                <p>{passedState.so.DN?.invoiceNumber}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Shipping date</h5>
                                <p>{passedState.so.DN?.dateShipped}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Packed dimensions</h5>
                                <p>{passedState.so.DN?.dimensions}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Total gross weight</h5>
                                <p>{passedState.so.DN?.weight}</p>
                            </div>
                        </div>
                    </div>
                    <Table className='mb-5' bordered hover>
                        <thead>
                            <tr>
                                <th className='tbl-qty-w1'>#</th>
                                <th className='tbl-qty-w3'>Quantity</th>
                                <th className='tbl-qty-w4'>Part number</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passedState.so.parts.map((value, index: any) => {
                                console.log(value)
                                return (
                                    <tr>
                                        <td className=''>{index + 1}</td>
                                        <td className=''>{value.quantity}</td>
                                        <td className=''>{value.partNumber}</td>
                                        <td>{value.description}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <div className='mt-5 mb-2 d-flex flex-column'>
                        <h5 className="mb-0 purchaseOrdersCreate-template__green-text">Notes & Special instructions</h5>
                        <p>{passedState.so.DN?.notes}</p>
                    </div>
                    <div className='mt-5 mb-5 d-flex flex-column w-100'>
                        <div className='d-flex flex-row justify-content-between w-100'>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Print name:</h5>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Signature:</h5>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Date:</h5>
                        </div>
                    </div>
                    <div className="mt-4">
                        <p><span className="font-weight-bold">Therco-Serck Ltd</span> Rother Valley Way, Holbrook, Sheffield, S20 3RW, United Kingdom</p>
                        <div className="d-flex flex-row">
                            <p className="mr-2"><span className="font-weight-bold">T:</span> 0114 251 0339</p>
                            <p className="mr-2"><span className="font-weight-bold">F:</span> 0114 251 0564</p>
                            <p className="mr-2"><span className="font-weight-bold">E:</span> info@ThercoSerckHTX.com</p>
                            <p><span className="font-weight-bold">W:</span> ThercoSerckHTX.com</p>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-end w-100 PO-printing">
                        <Button
                            onClick={() => setPreview(!preview)}
                            className="button-green mt-5 w-auto">
                            Cancel preview
                        </Button>
                        <Button
                            onClick={() => window.print()}
                            className="button-green mt-5 ml-2 w-auto">
                            Download PDF
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Screen active="Delivery note">
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`DELIVERY NOTE`}</h2>
                    <div className="d-flex flex-column mt-4 mb-5">
                        <h5 className="mb-0 purchaseOrdersCreate-template__green-text">Delivery address</h5>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressOne}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressTwo}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryAddressThree}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryTownCity}</p>
                        <p>{passedState.quote.enquiry.customer.deliveryPostCode}</p>
                    </div>
                    <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Therco-Serck reference</h5>
                                <p>TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Therco-Serck contact</h5>
                                <p>{passedState.so.DN?.contact}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Customer PO</h5>
                                <p>{passedState.so.customerPo}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Order number</h5>
                                <p>{passedState.so.DN?.orderNumber}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Commodity code</h5>
                                <p>{passedState.so.DN?.commodity}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Invoice number</h5>
                                <p>{passedState.so.DN?.invoiceNumber}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Shipping date</h5>
                                <p>{passedState.so.DN?.dateShipped}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Packed dimensions</h5>
                                <p>{passedState.so.DN?.dimensions}</p>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">Total gross weight</h5>
                                <p>{passedState.so.DN?.weight}</p>
                            </div>
                        </div>
                    </div>
                    <Table className='mb-5' bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Quantity</th>
                                <th>Part number</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passedState.so.parts.map((value, index: any) => {
                                console.log(value)
                                return (
                                    <tr>
                                        <td className='small-qty'>{index + 1}</td>
                                        <td className='small-qty'>{value.quantity}</td>
                                        <td className='small-cell'>{value.partNumber}</td>
                                        <td>{value.description}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <div className='mt-5 mb-2 d-flex flex-column'>
                        <h5 className="mb-0 purchaseOrdersCreate-template__green-text">Notes & Special instructions</h5>
                        <p>{passedState.so.DN?.notes}</p>
                    </div>
                    <div className='mt-5 mb-5 d-flex flex-column w-100'>
                        <div className='d-flex flex-row justify-content-between w-100'>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Print name:</h5>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Signature:</h5>
                            <h5 className="mb-0 mr-custom purchaseOrdersCreate-template__green-text">Date:</h5>
                        </div>
                    </div>
                    <div className="mt-4">
                        <p><span className="font-weight-bold">Therco-Serck Ltd</span> Rother Valley Way, Holbrook, Sheffield, S20 3RW, United Kingdom</p>
                        <div className="d-flex flex-row">
                            <p className="mr-2"><span className="font-weight-bold">T:</span> 0114 251 0339</p>
                            <p className="mr-2"><span className="font-weight-bold">F:</span> 0114 251 0564</p>
                            <p className="mr-2"><span className="font-weight-bold">E:</span> info@ThercoSerckHTX.com</p>
                            <p><span className="font-weight-bold">W:</span> ThercoSerckHTX.com</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                        <Button
                            onClick={() => setPreview(!preview)}
                            className="button-green mt-5 w-auto">
                            View preview & print
                        </Button>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default DeliveryNoteView